export default {
    "/": {
        "path": "Home",
        "icon": "el-icon-menu",
        "name": "主页",
        "power": ""
    },
    "/login": {
        "path": "Login",
        "icon": "fa fa-home",
        "name": "登录",
        "power": "",
        "disabled": true
    },
    "/volunteer": {
        "path": "volunteerManagement/volunteerManagement",
        "icon": "el-icon-info",
        "name": "志愿者管理",
        "power": ""
    },
    "/org": {
        "path": "organizationManagement/organizationManagement",
        "icon": "el-icon-news",
        "name": "组织管理",
        "power": ""
    },
    // "/task": {
    //     "path": "taskManagement/taskManagement",
    //     "icon": "el-icon-s-check",
    //     "name": "任务管理",
    //     "power": ""
    // },
    "/topimg": {
        "path": "topImgManagement/topImgManagement",
        "icon": "el-icon-message",
        "name": "顶图管理",
        "power": ""
    },
    "/activity": {
        "path": "activityManagement/activityManagement",
        "icon": "el-icon-star-on",
        "name": "活动管理",
        "power": ""
    },
    "/message": {
        "path": "messageManage/messageManage",
        "icon": "el-icon-message",
        "name": "信息管理",
        "power": ""
    },
    "/certificate": {
        "path": "certificateManagement/certificateManagement",
        "icon": "el-icon-picture",
        "name": "证书管理",
        "power": ""
    },
    "/question": {
        "path": "faqManagement/faqManagement",
        "icon": "el-icon-service",
        "name": "问答管理",
        "power": ""
    },
    "/title": {
        "path": "titleManagement/titleManagement",
        "icon": "el-icon-bell",
        "name": "称号管理",
        "power": ""
    },
    "/content": {
        "path": "contentManagement/contentManagement",
        "icon": "el-icon-document",
        "name": "内容管理",
        "power": ""
    },
    "/system": {
        "path": "systemManagement/systemManagement",
        "icon": "el-icon-location",
        "name": "系统管理",
        "power": ""
    },
    "/settingMenu": {
        "path": "settingMenu/settingMenu",
        "icon": "el-icon-guide",
        "name": "自定义菜单",
        "power": ""
    }
}

