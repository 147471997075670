<template>
  <el-row :gutter="20">
    <el-col
      :span="6"
      class="itemRight"
    >{{this.keyName}}:</el-col>
    <el-col
      :span="18"
      class="itemLeft"
    >{{this.keyValue?this.keyValue:"-"}}</el-col>
  </el-row>
</template>
<script>
export default {
  props: {
    keyName: String,
    keyValue: String,
  },
};
</script>
<style>
.itemRight {
  text-align: right;
}
.itemLeft {
  text-align: left;
}
</style>