<template>
  <el-drawer
    title="新增称号"
    :model-value="this.isVisible"
    size="80%"
    destroy-on-close
    @close="this.$emit('closeAct')"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="assContent_areaBody">
      <el-form
        ref="submitData"
        :model="submitData"
        label-width="150px"
        :rules="rules"
      >
        <el-form-item
          label="内容标题"
          prop="title"
        >
          <el-input v-model="submitData.title"></el-input>
        </el-form-item>
        <el-form-item
          label="引言"
          prop="introducer"
        >
          <el-input
            type="textarea"
            :rows="2"
            v-model="submitData.introducer"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="发布人"
          prop="publisher"
        >
          <el-input v-model="submitData.publisher"></el-input>
        </el-form-item>

        <el-form-item
          label="内容分类"
          prop="type"
        >
          <el-radio-group
            v-model="submitData.type"
            size="small"
          >
            <el-radio-button label=1>最新动态</el-radio-button>
            <el-radio-button label=2>公益科普</el-radio-button>
            <el-radio-button label=3>公益校园</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="发布时间"
          prop="date"
        >
          <el-date-picker
            v-model="submitData.date"
            type="datetime"
            placeholder="选择日期时间"
            :default-time="defaultTime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="缩略图"
          prop="status"
        >
          <el-upload
            action="https://up-z2.qiniup.com"
            list-type="text"
            :on-success="uploadSuccess"
            :before-upload="getQNToken"
            :data="qiniuData"
            accept=".png,.jpg"
            :show-file-list="false"
          >
            <el-button
              size="small"
              type="primary"
            >点击上传</el-button>
          </el-upload>
          <el-image
            v-if="this.submitData.thumbnailURL != ''"
            style="width: 100px; height: 100px"
            :src="`${this.submitData.thumbnailURL}`"
            :fit="fill"
          ></el-image>
        </el-form-item>
        <el-form-item label="活动内容">
          <TinymceEditor
            :value="submitData.content"
            @updateValue="this.updateContent"
            ref="editor"
          ></TinymceEditor>
        </el-form-item>
      </el-form>
    </div>
    <div class="thisFooter">
      <span class="dialog-footer">
        <el-button
          @click="this.$emit('closeAct')"
          :loading="this.submitLoading"
        >取 消</el-button>
        <el-button
          type="primary"
          @click="this.submit"
          :loading="this.submitLoading"
        >确 定</el-button>
      </span>
    </div>
  </el-drawer>
</template>
<script>
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
import TinymceEditor from "@/components/tinyMCE";
import moment from "moment";
export default {
  props: { isVisible: Boolean, closeAct: Function, addSuccess: Function },
  data() {
    return {
      visible: false,
      powerList: [],
      submitLoading: false,
      qiniuData: {
        token: "",
        // key: "",
      },
      submitData: {
        title: "",
        introducer: "",
        type: 1,
        date: "",
        pubDate: 0,
        publisher: "",
        thumbnailURL: "",
        content: "",
      },
      surekeyword: "",
      rules: {
        title: [{ required: true, message: "请输入内容标题", trigger: "blur" }],
        date: [{ required: true, message: "请选择时间", trigger: "blur" }],
        publisher: [
          { required: true, message: "请输入发布人", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    isVisible(newValue) {
      console.log(newValue);
      this.visible = newValue;
      this.submitData = {
        title: "",
        introducer: "",
        publisher: "",
        type: 1,
        date: "",
        pubDate: 0,
        thumbnailURL: "",
        content: "",
      };
    },
  },
  components: {
    TinymceEditor,
  },
  methods: {
    moment,
    getQNToken() {
      return ax("getqiniutoken")
        .then((res3) => {
          console.log(res3);
          //   setQNToken(res3.data);
          this.qiniuData.token = res3.data;
          return true;
        })
        .catch((error) => {
          console.log(error);
          // this.loginNow = true;
          // sessionStorage.clear();
          ElMessage.error("上传失败。");
          return false;
        });
    },
    uploadSuccess(res) {
      console.log(res);
      this.submitData.thumbnailURL = "https://cdn.fryj.top/" + res.key;
    },
    updateContent(val) {
      console.log(val);
      this.submitData.content = val;
    },
    getPowers() {
      console.log(this.$refs.tree.getCheckedNodes());
      let getPower = this.$refs.tree.getCheckedNodes();
      let returnData = [];
      for (let i in getPower) {
        if (typeof getPower[i].id == "number") returnData.push(getPower[i].id);
      }
      return returnData;
    },
    submit() {
      this.$refs["submitData"].validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          let submitJSON = { ...this.submitData };
          let toInt = ["type"];
          for (let i in toInt) {
            if (submitJSON[toInt[i]] == "") {
              submitJSON[toInt[i]] = 0;
            } else if (typeof submitJSON[toInt[i]] == "string") {
              submitJSON[toInt[i]] = parseInt(submitJSON[toInt[i]]);
            }
          }
          submitJSON.pubDate = parseInt(
            moment(submitJSON.time).valueOf() / 1000
          );
          ax("addContent", { data: submitJSON })
            .then((res) => {
              console.log(res);
              this.submitLoading = false;
              // this.$refs["submitData"].resetFields();
              this.$emit("addSuccess", {
                id: res.data,
                ...submitJSON,
                status: 1,
              });
            })
            .catch((err) => {
              console.log(err);
              this.submitLoading = false;
              ElMessage.error(err.msg);
            });
          //   console.log(submitJSON)
          // 获取权限
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
.assContent_areaBody {
  // max-height: 350px;
  padding: 0 16px;
  overflow: auto;
}
.thisFooter {
  text-align: right;
  padding-right: 16px;
}
</style>