import { createApp, h } from 'vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import './main.less'
import routes from './routes'
// import { createRouter, createWebHistory } from 'vue-router'
import { isToken } from '@/tools/util'

// function baseUrl() {
//     if (process.env.NODE_ENV === 'development') {
//         url = "/pro"
//     }
//     if (process.env.NODE_ENV === 'production') {
//         url = ""
//     }
// }

const Router = {
    data: () => ({
        currentRoute: window.location.pathname
    }),

    computed: {
        ViewComponent() {
            // console.log(isToken())
            // console.log(routes[this.currentRoute])
            // console.log(routes)
            if (isToken()) {
                if (routes[this.currentRoute].path == "Login") {
                    // history.pushState()
                    window.location.href = '/'
                    return;
                }
                const matchingPage = routes[this.currentRoute].path || '404'
                return require(`./pages/${matchingPage}.vue`).default
            }
            //   if(routes[this.currentRoute] ==)
            // console.log(routes[this.currentRoute])
            if (routes[this.currentRoute].path == "Login") {
                return require(`./pages/login.vue`).default
            }
            window.location.href = '/login'
            //   routes.push('/login')
        }
    },

    render() {
        return h(this.ViewComponent)
    },

    created() {
        window.addEventListener('popstate', () => {
            this.currentRoute = window.location.pathname
        })
    }
}



// const router = createRouter({
//     //history
//     // history: createWebHashHistory(process.env.BASE_URL),
//     //hash
//     history: createWebHistory(process.env.BASE_URL),
//     routes
// });

const app = createApp(Router)

app.use(ElementPlus, { locale: zhCn });
app.use(routes)

app.mount('#app')

