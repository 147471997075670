import axios from 'axios';
import config from './config';
import indexServiceUse from './indexServiceUse';
import { isToken, getToken } from '@/tools/util';


export function ax(use, exRequestConfig = {}) {
    let requestConfig = {
        // method: 'get',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Access-Control-Allow-Origin': '*'
        },
        baseURL: process.env.NODE_ENV === "development" ? config.baseUrl.dev : config.baseUrl.prd,
        timeout: 60000,
        ...indexServiceUse[use]
    }
    // if (use == "login" && process.env.NODE_ENV != "development") requestConfig.baseURL = ""
    if (exRequestConfig.data) {
        if (requestConfig.method == 'get') requestConfig.params = exRequestConfig.data;
        requestConfig.data = exRequestConfig.data;
    }
    console.log(requestConfig)
    if (exRequestConfig.urlParam) {
        for (let i in exRequestConfig.urlParam) {
            // console.log(`{${i}}`)
            requestConfig.url = requestConfig.url.replaceAll(`{${i}}`, exRequestConfig.urlParam[i])
        }
    }
    console.log(requestConfig)
    console.log(getToken())
    if (isToken()) {
        requestConfig.headers.token = 'BEARER ' + getToken();
    }
    console.log(requestConfig)
    // if(exRequestConfig.urlParam) return
    return new Promise(function (resolve, reject) {
        axios(requestConfig)
            .then((response) => {
                console.log(response)
                if (exRequestConfig.typeFile) {
                    // data就是接口返回的文件流
                    let data = response.data;
                    let BOM = '\uFEFF'
                    if (data) {
                        // 处理文件名
                        let fileName = ''
                        let attrs = response.headers['content-disposition'].split(';')
                        for (let i = 0, l = attrs.length; i < l; i++) {
                            let temp = attrs[i].split('=')
                            if (temp.length > 1 && temp[0] === 'filename') {
                                fileName = temp[1]
                                break
                            }
                        }
                        fileName = decodeURIComponent(fileName)
                        console.log(fileName)

                        // 获取数据类型
                        // let type = response.headers['content-type'].split(';')[0]
                        // type就是文件的mime类型，一般接口会返回。csv文件的mime一般采用text/csv
                        let blob = new Blob([BOM + data], { type: response.headers['content-type'] })
                        console.log(response.headers['content-type'])
                        // if (type == 'text/csv') { fileName += ".csv" }
                        // console.log(response.headers['content-disposition'].split(';')[1])
                        const a = document.createElement('a')

                        // 创建URL
                        const blobUrl = window.URL.createObjectURL(blob)
                        a.download = fileName
                        a.href = blobUrl
                        document.body.appendChild(a)

                        // 下载文件
                        a.click()

                        // 释放内存
                        URL.revokeObjectURL(blobUrl)
                        document.body.removeChild(a)

                        console.log(response)
                        resolve({ code: 0 })
                    } else {
                        reject({ code: 50011, msg: "出错了，请重试。" })
                    }
                }
                if (response.data.code == 0) { resolve(response.data) }
                else {
                    let errData = { code: response.data.code, msg: "出错了，请重试" }
                    if (response.data.code > 0) {
                        errData = { code: response.data.code, msg: response.data.msg ? response.data.msg : "出错了，请重试" }
                    }
                    if (response.data.code == -1 && response.data.msg) {
                        errData = { code: response.data.code, msg: response.data.msg ? response.data.msg : "出错了，请重试" }
                    }
                    reject(errData)
                }
            })
            .catch((error) => {
                console.log(error)
                let errData = { code: error.data.code, msg: "出错了，请重试" }
                if (error.data.code > 0) {
                    errData = { code: error.data.code, msg: error.data.msg ? error.data.msg : "出错了，请重试" }
                }
                reject(errData)
            })
    })

}