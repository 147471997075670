<template>
  <main-layout>
    <div v-if="!(this.powerListCheck(this.allPower).allPowerCheck)">
      <el-alert
        title="您无权查看本页面。"
        type="warning"
        show-icon
        :closable="false"
      />
    </div>
    <div
      class="pages"
      v-loading="loading"
      v-if="this.powerCheck('获取活动列表')"
    >
      <el-card class="box-card">
        <div class="activity_ctrlCol">
          <div class="activity_searchCol">
            <div class="activity_searchCol_input">
              <el-input
                v-model="searchKeyWordInput"
                placeholder="请输入搜索内容"
                size="small"
              ></el-input>
            </div>
            <div class="activity_searchCol_button">
              <el-button
                type="primary"
                size="small"
                @click="this.getData"
              >搜索</el-button>
            </div>
          </div>
          <div class="activity_functionCol">
            <div class="activity_searchCol_button">
              <el-button
                type="primary"
                size="small"
                @click="this.addVisible=true"
                v-if="this.powerCheck('添加活动')"
              >添加活动</el-button>
            </div>
          </div>
        </div>
        <div class="activity_table">
          <el-table
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              label=" "
              width="80"
            >
              <template #default="scope">
                <span style="margin-left: 10px">{{ ((this.pageNum - 1) * this.pageSize) + (scope.$index + 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="活动名称"
            />
            <el-table-column label="报名开始日期">
              <template #default="scope">
                {{ moment(scope.row.signStartTime*1000).format('YYYY-MM-DD') }}
                <br />
                {{ moment(scope.row.signStartTime*1000).format('HH:mm:ss') }}
              </template>
            </el-table-column>
            <el-table-column
              label="报名结束日期"
              sortable
            >
              <template #default="scope">
                {{ moment(scope.row.signEndTime*1000).format('YYYY-MM-DD') }}
                <br />
                {{ moment(scope.row.signEndTime*1000).format('HH:mm:ss') }}
              </template>
            </el-table-column>
            <el-table-column
              label="活动开始日期"
              sortable
            >
              <template #default="scope">
                {{ moment(scope.row.startTime*1000).format('YYYY-MM-DD') }}
                <br />
                {{ moment(scope.row.startTime*1000).format('HH:mm:ss') }}
              </template>
            </el-table-column>
            <el-table-column
              label="活动结束日期"
              sortable
            >
              <template #default="scope">
                {{ moment(scope.row.endTime*1000).format('YYYY-MM-DD') }}
                <br />
                {{ moment(scope.row.endTime*1000).format('HH:mm:ss') }}
              </template>
            </el-table-column>
            <el-table-column
              prop="target"
              label="目标人数"
              sortable
            />
            <el-table-column
              prop="address"
              label="活动人数"
            />
            <el-table-column label="状态">
              <template #default="scope">
                <div
                  v-for="(item,index) in this.getState(scope.row.signStartTime,scope.row.signEndTime,scope.row.startTime,scope.row.endTime)"
                  :key="index"
                >
                  <span
                    v-if="item.si=='gary'"
                    style="margin-right:6px;color:#696969"
                  >●</span>
                  <span
                    v-if="item.si=='primary'"
                    style="margin-right:6px;color:#FF3030"
                  >●</span>
                  <span
                    v-if="item.si=='success'"
                    style="margin-right:6px;color:#1E90FF"
                  >●</span>
                  {{item.wr}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="420"
            >
              <template #default="scope">
                <el-button
                  type="success"
                  size="small"
                  @click="this.creatURL(scope.row.id)"
                >生成链接</el-button>
                <el-button
                  type="primary"
                  size="small"
                  @click="this.startEdit(scope.row.id)"
                  v-if="this.powerCheck('编辑活动')"
                >编辑</el-button>
                <el-button
                  type="success"
                  size="small"
                  @click="this.startParticipation(scope.row.id)"
                  v-if="this.powerCheck('获取活动参与人列表')"
                >参与与奖励</el-button>
                <el-button
                  type="danger"
                  size="small"
                  @click="this.delAct(scope.row.id)"
                  v-if="this.powerCheck('删除活动')"
                >删除</el-button>
                <el-button
                  type="success"
                  size="small"
                  @click="this.exportData(scope.row.id)"
                  v-if="this.powerCheck('获取志愿者列表')"
                >导出</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="this.sizeChange"
            @current-change="this.pageChange"
            :current-page="this.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="this.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.total"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <AddActivity
      :isVisible="this.addVisible"
      @closeAct="this.closeAdd"
      @addSuccess="this.addSuccess"
    />
    <EditActivity
      :isVisible="this.editVisible"
      :editData="this.editData"
      @closeAct="this.closeEdit"
      @editSuccess="this.editSuccess"
    />
    <ParticipationActivity
      :isVisible="this.participationVisible"
      :showID="this.participationActivityID"
      @closeAct="this.closeParticipation"
    />
  </main-layout>
</template> 
 
<script>
import MainLayout from "@/layouts/Main.vue";
import AddActivity from "./addActivity.vue";
import EditActivity from "./editActivity.vue";
import ParticipationActivity from "./participationActivity.vue";
import { powerCheck, powerListCheck } from "@/tools/util";
import { ax } from "@/service/http";
import moment from "moment";
import QRCode from "qrcode";
import { ElMessage } from "element-plus";
// import { ref } from "vue";
export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 20,
      total: 1,
      loading: false,
      allPower: ["获取活动列表"],
      searchKeyWordInput: "",
      addVisible: false,
      editVisible: false,
      participationVisible: false,
      tableData: [],
      editData: 0,
      participationActivityID: 0,
    };
  },
  loading: false,
  components: {
    MainLayout,
    AddActivity,
    EditActivity,
    ParticipationActivity,
  },
  created() {
    this.getData();
    // this.getQiniuToken();
  },
  methods: {
    powerCheck,
    powerListCheck,
    moment,
    closeAdd() {
      this.addVisible = false;
    },
    getState(sst, est, st, et) {
      let stateWord = [];
      let thisTime = moment().valueOf();
      // console.log(thisTime);
      if (sst * 1000 > thisTime) {
        stateWord.push({ si: "primary", wr: "报名未开始" });
      } else if (est * 1000 < thisTime) {
        stateWord.push({ si: "gary", wr: "报名已结束" });
      } else {
        stateWord.push({ si: "success", wr: "正在报名" });
      }
      if (st * 1000 > thisTime) {
        stateWord.push({ si: "primary", wr: "活动未开始" });
      } else if (et * 1000 < thisTime) {
        stateWord.push({ si: "gary", wr: "活动已结束" });
      } else {
        stateWord.push({ si: "success", wr: "活动进行中" });
      }
      return stateWord;
    },
    addSuccess(newData) {
      console.log(newData);
      this.addVisible = false;
      this.tableData = [...this.tableData, newData];
    },
    editSuccess(newData) {
      console.log(newData);
      let newTableData = [...this.tableData];
      for (let i in newTableData) {
        if (newData.id == newTableData[i].id) newTableData[i] = newData;
      }
      this.tableData = newTableData;
      this.editVisible = false;
    },
    startEdit(idx) {
      this.editData = idx;
      this.editVisible = true;
    },
    closeEdit() {
      this.editVisible = false;
    },
    startParticipation(idx) {
      console.log(idx);
      this.participationActivityID = idx;
      this.participationVisible = true;
    },
    closeParticipation() {
      this.participationVisible = false;
    },
    sizeChange(e) {
      this.pageSize = e;
      this.getData();
    },
    pageChange(e) {
      this.pageNum = e;
      this.getData();
    },
    creatURL(id) {
      let urlWord = `https://wx.fryj.top/activeregisterarticle/${id}`;
      QRCode.toDataURL(urlWord)
        .then((url) => {
          this.$alert(
            `
              <div>${urlWord}</div>
              <div>
                <img src="${url}">
              </div>
            `,
            "URL链接",
            {
              confirmButtonText: "确定",
              dangerouslyUseHTMLString: true,
            }
          );
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getData() {
      // console.log(e)
      this.loading = true;
      let queryObject = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchKeyWordInput != "") {
        console.log(this.searchKeyWordInput);
        queryObject.searchText = this.searchKeyWordInput;
      }
      console.log(queryObject);
      // if (this.searchText != "") {
      //   queryObject.searchText = this.searchText;
      // }
      ax("getactivityList", { data: queryObject })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    delAct(id) {
      ax("delActivity", { urlParam: { id } })
        .then(() => {
          let newTableData = [];
          for (let i in this.tableData) {
            if (id != this.tableData[i].id)
              newTableData.push(this.tableData[i]);
          }
          this.tableData = newTableData;
          ElMessage.success({
            message: "活动已删除",
            type: "success",
          });
        })
        .catch((e) => {
          ElMessage.error({
            message: e.msg,
            type: "error",
          });
        });
    },
    exportData(id) {
      ax("exportActive", { urlParam: { id: id }, typeFile: true })
        .then((res) => {
          console.log(res);
          // if (!res.data.list) res.data.list = [];
          // this.tableData = res.data.list;
          // this.total = res.data.total;
          // this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less">
.activity_ctrlCol {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.activity_chooseSchool,
.activity_searchCol {
  flex: 2;
}
.activity_searchCol {
  display: flex;
  align-items: center;
}
.activity_searchCol_input {
  width: 200px;
}
.activity_searchCol_button {
  flex: 1;
  padding-left: 10px;
}
.activity_functionCol {
  flex: 2;
  text-align: right;
}
.activity_table {
}
</style>
