<template>
  <main-layout>
    <div v-if="!(this.powerListCheck(this.allPower).allPowerCheck)">
      <el-alert
        title="您无权查看本页面。"
        type="warning"
        show-icon
        :closable="false"
      />
    </div>
    <div
      class="pages"
      v-loading="loading"
      v-if="this.powerCheck('获取组织列表')"
    >
      <el-card class="box-card">
        <div class="organization_ctrlCol">
          <div class="organization_searchCol">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
            >
              <el-form-item label="组织名">
                <el-input
                  v-model="formInline.name"
                  placeholder="请输入全部或部分组织名"
                ></el-input>
              </el-form-item>
              <el-form-item label="组织类型">
                <el-select
                  v-model="formInline.type"
                  placeholder="活动区域"
                >
                  <el-option
                    label="学校组织"
                    value=1
                  ></el-option>
                  <el-option
                    label="社会组织"
                    value=2
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属地区">
                <AddressChoose
                  @updateData="this.dealAddress"
                  :propsData="formInline.address"
                />
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="this.getData"
                >查询</el-button>
                <el-button
                  type="primary"
                  @click="this.clearformInline"
                >清除条件</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="organization_functionCol">
            <div class="organization_searchCol_button">
              <el-button
                type="primary"
                size="small"
                @click="this.addOrganization(-1)"
                v-if="this.powerCheck('添加组织')"
              >添加组织</el-button>
            </div>
          </div>
        </div>
        <div class="organization_table">
          <el-table
            :data="tableData"
            border
            style="width: 100%"
          >
            <!-- <el-table-column
              prop="index"
              label=" "
              width="80"
            >

            </el-table-column> -->
            <el-table-column
              prop="date"
              label="序号"
              width="80"
            >
              <template #default="scope">
                <span style="margin-left: 10px">{{ ((this.pageNum - 1) * this.pageSize) + (scope.$index + 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="组织名称"
            />
            <el-table-column
              prop="name"
              label="类型"
            >
              <template #default="scope">
                <span>{{ (scope.row.type && "["+this.orgType[scope.row.type] + "]") }}</span>
                <span style="margin-left: 6px">{{ (scope.row.schoolType &&  this.schoolType[scope.row.schoolType]) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="地区">
              <template #default="scope">
                <span>{{ (scope.row.province && scope.row.province) }}</span>
                <span style="margin-left: 10px">{{ (scope.row.city && scope.row.city) }}</span>
                <span style="margin-left: 10px">{{ (scope.row.county && scope.row.county) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="describe"
              label="组织描述"
              :formatter="simpleDesc"
            />
            <el-table-column
              prop="peopleCount"
              label="组织总人数"
              sortable
            />
            <el-table-column
              prop="volunteerCount"
              label="已加入人数"
              sortable
            />
            <!-- <el-table-column
              prop="address"
              label="下级组织数量"
            >
              <template #default="scope">
                <span style="margin-left: 10px">{{ scope.row.children?scope.row.children.length:0  }}</span>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="address"
              label="操作"
              width="220px"
            >
              <template #default="scope">
                <!-- <el-button
                  type="primary"
                  size="small"
                  @click="this.addOrganization(scope.row.id,scope.row)"
                >新增</el-button> -->

                <el-button
                  type="primary"
                  size="small"
                  @click="this.startEdit(scope.$index,-1)"
                  v-if="this.powerCheck('编辑组织')"
                >编辑</el-button>

                <!--<el-button
                  type="warning"
                  size="small"
                  @click="this.resetPassword(scope.row.id)"
                >重置密码</el-button>-->
                <el-popconfirm
                  confirmButtonText="确定"
                  cancelButtonText="取消"
                  icon="el-icon-info"
                  iconColor="red"
                  title="确定删除吗？"
                  @confirm="this.delete(scope.row.id,-1)"
                >
                  <template #reference>
                    <el-button
                      type="danger"
                      size="small"
                      v-if="this.powerCheck('删除组织')"
                    >删除</el-button>
                  </template>
                </el-popconfirm>
                <el-button
                  type="success"
                  size="small"
                  @click="this.setStatistics(scope.row.id)"
                >统计</el-button>
                <!-- <el-button
                  type="danger"
                  size="small"
                  v-if="scope.row.id!=1"
                  @click="this.delete(scope.row.id)"
                >删除</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="this.sizeChange"
            @current-change="this.pageChange"
            :current-page="this.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="this.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.total"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <AddOrganization
      :isVisible="this.addVisible"
      :addOrganizationData="this.addOrganizationData"
      @closeAct="this.closeAdd"
      @addSuccess="this.addSuccess"
    />
    <EditOrganization
      :isVisible="this.editVisible"
      :editOrganizationData="this.editData"
      @closeAct="this.closeEdit"
      @editSuccess="this.editSuccess"
    />
    <ShowStatistics
      :isVisible="this.statisticsVisible"
      :statisticsOrganizationData="this.statisticsID"
      @closeAct="this.closeStatistics"
    />
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/Main.vue";
import AddOrganization from "./addOrganization";
import EditOrganization from "./editOrganization";
import ShowStatistics from "./showStatistics";
import AddressChoose from "@/components/addressChoose";
import { powerCheck, powerListCheck } from "@/tools/util";
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 20,
      total: 1,
      allPower: ["获取组织列表"],
      loading: false,
      formInline: { name: "", type: 0, address: [] },
      tableData: [],
      schoolType: {
        1: "大学",
        2: "高中",
        3: "中职",
        4: "初中",
        5: "小学",
        0: "未设置",
      },

      orgType: { 1: "学校", 2: "社会", 0: "未设置" },
      addVisible: false,
      addOrganizationData: {
        addParent: -1,
        addSchoolType: 1,
      },
      editVisible: false,
      editConfig: [],
      editData: {},
      statisticsVisible: false,
      statisticsID: 0,
    };
  },
  components: {
    MainLayout,
    AddOrganization,
    EditOrganization,
    AddressChoose,
    ShowStatistics,
  },
  created() {
    this.getData();
    // this.getQiniuToken();
  },
  methods: {
    powerCheck,
    powerListCheck,
    sizeChange(e) {
      this.pageSize = e;
      this.getData();
    },
    pageChange(e) {
      this.pageNum = e;
      this.getData();
    },
    dealAddress(e) {
      console.log(e);
      this.formInline.address = e.value;
    },
    clearformInline() {
      window.location.reload();
      // this.formInline = { name: "", type: 0, address: [] };
      // this.getData();
    },
    showStatistics(id) {
      console.log(id);
    },
    getData() {
      // console.log(e)
      this.loading = true;
      let queryObject = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      console.log(queryObject);
      if (this.formInline.name != "") {
        queryObject.searchText = this.formInline.name;
      }
      if (this.formInline.type != 0) {
        queryObject.type = this.formInline.type;
      }
      if (this.formInline.address.length != 0) {
        for (let i in this.formInline.address) {
          if (i == 0) queryObject.provinceIndex = this.formInline.address[i];
          if (i == 1) queryObject.cityIndex = this.formInline.address[i];
          if (i == 2) queryObject.countyIndex = this.formInline.address[i];
        }
        // queryObject.searchText = this.formInline.name;
      }
      ax("getOrgList", { data: queryObject })
        .then((res) => {
          console.log(res);
          if (!res.data.list) res.data.list = [];
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    simpleDesc(row) {
      // console.log(row.describe);
      if (row.describe) {
        if (row.describe.length > 60) {
          let data = row.describe.substring(0, 60) + "...";
          return data;
        } else {
          return row.describe;
        }
      }
      return "";
    },
    closeAdd() {
      this.addVisible = false;
    },
    closeEdit() {
      this.editVisible = false;
    },
    setStatistics(id) {
      this.statisticsID = id;
      this.statisticsVisible = true;
    },
    closeStatistics() {
      this.statisticsVisible = false;
    },
    addOrganization(addType, rowData) {
      if (addType == -1) {
        this.addOrganizationData = {
          addParent: -1,
        };
      } else {
        this.addOrganizationData = {
          addParent: addType,
          ...rowData,
        };
      }
      this.addVisible = true;
    },
    addSuccess(newData) {
      this.addVisible = false;
      this.tableData = [newData, ...this.tableData];
    },
    startEdit(rowIndex, editIndex) {
      console.log(rowIndex, editIndex);
      if (editIndex == -1) {
        console.log(this.tableData[rowIndex]);
        this.editData = this.tableData[rowIndex];
        this.editConfig = [rowIndex, editIndex];
        this.editVisible = true;
      }
    },
    editSuccess(newData) {
      // console.log(newData);
      if (newData.level == 1) {
        let newTableData = [...this.tableData];
        for (let i in newTableData) {
          // console.log(newData.id , newTableData[i].id)
          if (newData.id == newTableData[i].id) newTableData[i] = { 
            ...newTableData[i],...newData
          };
        }
        console.log(newTableData);
        this.tableData = newTableData;
        this.editVisible = false;
      }
    },
    delete(id) {
      ax("delOrg", { urlParam: { id } })
        .then(() => {
          let newTableData = [];
          for (let i in this.tableData) {
            if (id != this.tableData[i].id)
              newTableData.push(this.tableData[i]);
          }
          this.tableData = newTableData;
          ElMessage.success({
            message: "组织已删除",
            type: "success",
          });
        })
        .catch((e) => {
          ElMessage.error({
            message: e.msg,
            type: "error",
          });
        });
    },
  },
};
</script>

<style lang="less">
.organization_ctrlCol {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.organization_chooseSchool {
  flex: 1;
}
.organization_searchCol {
  flex: 5;
}
.organization_searchCol {
  display: flex;
  align-items: center;
}
.organization_searchCol_input {
  width: 200px;
}
.organization_searchCol_button {
  flex: 1;
  padding-left: 10px;
}
.organization_functionCol {
  flex: 2;
  text-align: right;
}
.organization_table {
}
</style>
