export default function getUserInfo() {
    return sessionStorage.UserInfo
}
export function getUserHours(timeSeconds) {
    let h = timeSeconds / 3600;
    return h.toFixed(1)
}
export function getDay(timestamp) {
    let date = new Date(timestamp)
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate();
    return `${y}-${m}-${d}`
}
export function clear() {
    return sessionStorage.clear()
}
export function setUserName(name) {
    sessionStorage.name = name
}
export function getUserName() {
    return sessionStorage.name
}
export function setUserId(id) {
    sessionStorage.id = id
}
export function getUserId() {
    return sessionStorage.id
}
export function isToken() {
    if (sessionStorage.token) return true;
    return false;
}
export function setToken(token) {
    sessionStorage.token = token
}
export function getToken() {
    return sessionStorage.token
}
export function setQNToken(token) {
    sessionStorage.qnToken = token
}
export function getQNToken() {
    return sessionStorage.qnToken
}
export function setPowers(powers) {
    sessionStorage.powers = powers
}
export function setAllPower(power) {
    // console.log(power);
    let powerIndex = {}
    let powerGroup = {}
    for (let i in power) {
        powerGroup[i] = [];
        for (let j in power[i]) {
            if (typeof powerIndex[power[i][j].power] == 'undefined') {
                powerIndex[power[i][j].power] = { name: power[i][j].name, useArray: [] }
            }
            powerGroup[i].push(power[i][j].power)
            powerIndex[power[i][j].power].useArray.push(i)
        }
    }
    powerIndex["idx"] = powerGroup;
    // console.log(powerIndex)
    sessionStorage.allPowers = JSON.stringify(powerIndex)
}
export function getPowers() {
    return sessionStorage.powers
}
export function getSinAllPowers(idx) {
    let powerIndex = JSON.parse(sessionStorage.allPowers);
    if (idx == "") return powerIndex;
    // console.log(powerIndex[idx])
    return powerIndex[idx];
}
export function setUserInfo(item) {
    sessionStorage.UserInfo = item
}
export function powerCheck(powerName) {
    // console.log(powerName)
    let nowPower = [];
    if (sessionStorage.powers) {
        nowPower = JSON.parse(sessionStorage.powers)
    }
    // console.log(nowPower)
    let allPowers = JSON.parse(sessionStorage.allPowers)
    // console.log(allPowers)
    for (let i in nowPower) {

        // console.log(allPowers[nowPower[i]])
        if (typeof allPowers[nowPower[i]] != "undefined") {
            if (allPowers[nowPower[i]].name == powerName) return true;
        }

    }
    // if (nowPower.indexOf(powerName) != -1) return true;
    return false;
}
export function powerListCheck(powerNameList) {
    // console.log(powerNameList)
    let allPowerCheck = true;
    let powerList = {};
    for (let i in powerNameList) {
        // console.log(powerNameList[i])
        let thisCheck = powerCheck(powerNameList[i]);
        // console.log(thisCheck)
        powerList[powerNameList[i]] = thisCheck;
        if (!thisCheck) allPowerCheck = false;
    }
    return {
        allPowerCheck,
        powerList
    }
}