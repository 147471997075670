<template>
  <main-layout>
    <div v-if="!(this.powerListCheck(this.allPower).allPowerCheck)">
      <el-alert
        title="您无权查看本页面。"
        type="warning"
        show-icon
        :closable="false"
      />
    </div>
    <div
      class="pages"
      v-loading="loading"
    >
      <el-card class="box-card">
        <div class="task_ctrlCol">
          <div class="task_searchCol">
            <div class="task_searchCol_input">
              <el-input
                v-model="searchKeyWordInput"
                placeholder="请输入搜索内容"
                size="small"
              ></el-input>
            </div>
            <div class="task_searchCol_button">
              <el-button
                type="primary"
                size="small"
              >搜索</el-button>
            </div>
          </div>
          <div class="task_functionCol">
            <div class="task_searchCol_button">
              <el-button
                type="primary"
                size="small"
              >添加任务</el-button>
            </div>
          </div>
        </div>
        <div class="task_table">
          <el-table
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              prop="index"
              label="序号"
              width="40"
            />
            <el-table-column
              prop="date"
              label="任务名称"
            />
            <el-table-column
              prop="name"
              label="任务范围"
            />
            <el-table-column
              prop="address"
              label="开始日期"
            />
            <el-table-column
              prop="address"
              label="结束日期"
            />
            <el-table-column
              prop="address"
              label="描述"
            />
            <el-table-column
              prop="address"
              label="状态"
            />
            <el-table-column
              prop="address"
              label="操作"
            />
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="20"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/Main.vue";
import { powerCheck, powerListCheck } from "@/tools/util";
import { ref } from "vue";
export default {
  data() {
    return {
      allPower: [],
      searchKeyWordInput: ref(""),
    };
  },
  loading: false,
  components: {
    MainLayout,
  },
  methods: {
    powerCheck,
    powerListCheck,
  },
};
</script>

<style lang="less">
.task_ctrlCol {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.task_chooseSchool,
.task_searchCol {
  flex: 2;
}
.task_searchCol {
  display: flex;
  align-items: center;
}
.task_searchCol_input {
  width: 200px;
}
.task_searchCol_button {
  flex: 1;
  padding-left: 10px;
}
.task_functionCol {
  flex: 2;
  text-align: right;
}
.task_table {
}
</style>
