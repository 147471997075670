<template>
  <main-layout>
    <div v-if="!(this.powerListCheck(this.allPower).allPowerCheck)">
      <el-alert
        title="您无权查看本页面。"
        type="warning"
        show-icon
        :closable="false"
      />
    </div>
    <div
      class="pages"
      v-loading="loading"
      v-if="this.powerCheck('获取管理员列表')"
    >
      <el-card class="box-card">
        <div class="systemManagement_ctrlCol">
          <!-- <div class="systemManagement_searchCol">
            <div class="systemManagement_searchCol_input">
              <el-input
                v-model="searchKeyWordInput"
                placeholder="请输入搜索内容"
                size="small"
              ></el-input>
            </div>
            <div class="systemManagement_searchCol_button">
              <el-button
                type="primary"
                size="small"
              >搜索</el-button>
            </div>
          </div>  -->
          <div class="systemManagement_functionCol">
            <div class="systemManagement_searchCol_button">
              <el-button
                type="primary"
                size="small"
                @click="this.startAdd"
                v-if="this.powerCheck('添加管理员')"
              >新增系统管理员</el-button>
            </div>
          </div>
        </div>
        <div class="systemManagement_table">
          <el-table
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              label=" "
              width="80"
            >
              <template #default="scope">
                <span style="margin-left: 10px">{{ ((this.pageNum - 1) * this.pageSize) + (scope.$index + 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="account"
              label="用户名"
            />
            <el-table-column
              prop="name"
              label="姓名"
            />
            <!-- <el-table-column
              label="用户权限"
            >
              <template #default="scope">
                <span v-for="(item, idx) in scope.row.powerList" :key="idx">
                  {{this.getSinAllPowers(item)?this.getSinAllPowers(item).name + "；":""}}
                </span>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="tel"
              label="联系电话"
            />
            <el-table-column
              prop="remark"
              label="备注"
            />
            <el-table-column
              label="操作"
              width="260"
            >
              <template #default="scope">
                <el-button
                  type="primary"
                  size="small"
                  @click="this.startEdit(scope.$index)"
                  v-if="this.powerCheck('编辑管理员')"
                >编辑</el-button>
                <el-button
                  type="warning"
                  size="small"
                  @click="this.resetPassword(scope.row.id)"
                  v-if="this.powerCheck('重置管理员密码')"
                >重置密码</el-button>
                <el-popconfirm
                  confirmButtonText="确定"
                  cancelButtonText="取消"
                  icon="el-icon-info"
                  iconColor="red"
                  title="确定删除吗？"
                  @confirm="this.delete(scope.row.id)"
                  v-if="scope.row.id!=1"
                >
                  <template #reference>
                    <el-button
                      type="danger"
                      size="small"
                      v-if="this.powerCheck('删除管理员')"
                    >删除</el-button>
                  </template>
                </el-popconfirm>
                <!-- <el-button
                  type="danger"
                  size="small"
                  v-if="scope.row.id!=1"
                  @click="this.delete(scope.row.id)"
                >删除</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="this.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="this.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.total"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <AddAccount
      :isVisible="this.addAccountVisible"
      @closeAct="this.closeAdd"
      @addSuccess="this.addSuccess"
    />
    <EditAccount
      :isVisible="this.editAccountVisible"
      :editData="this.editData"
      :userDataIdx="this.editIdx"
      @closeAct="this.closeEdit"
      @editSuccess="this.editSuccess"
    />
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/Main.vue";
import AddAccount from "./addAccount.vue";
import EditAccount from "./editAccount.vue";
import { powerCheck, powerListCheck, getSinAllPowers } from "@/tools/util";
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 20,
      total: 1,
      allPower: ["获取管理员列表"],
      tableData: [],
      loading: false,
      addAccountVisible: false,
      editAccountVisible: false,
      editIdx: 0,
      editData: {},
    };
  },
  components: {
    MainLayout,
    AddAccount,
    EditAccount,
  },
  created() {
    this.getData();
  },
  methods: {
    powerCheck,
    powerListCheck,
    getSinAllPowers,
    getData() {
      this.loading = true;
      let queryObject = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      ax("getManagerList", { data: queryObject })
        .then((res) => {
          // console.log(res);
          this.tableData = res.data.list;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          ElMessage.error("获取列表失败，请重试。");
          this.loading = false;
        });
    },
    startAdd() {
      this.addAccountVisible = true;
    },
    closeAdd() {
      this.addAccountVisible = false;
    },
    addSuccess(newData) {
      console.log(newData);
      this.addAccountVisible = false;
      this.tableData = [newData, ...this.tableData];
    },
    startEdit(idx) {
      console.log(idx);
      this.editIdx = idx;
      console.log({ ...this.tableData[idx] });
      this.editData = { ...this.tableData[idx] };
      // console.log(this.tableData);
      // console.log({...this.tableData[idx]});
      // console.log(this.editData);
      this.editAccountVisible = true;
    },
    closeEdit() {
      this.editAccountVisible = false;
    },
    editSuccess(newData) {
      console.log(newData);
      let newTableData = [...this.tableData];
      for (let i in newTableData) {
        if (newData.id == newTableData[i].id) newTableData[i] = newData;
      }
      this.tableData = newTableData;
      this.editAccountVisible = false;
    },
    resetPassword(id) {
      // console.log(id);
      ax("resetManagerPassword", { data: {}, urlParam: { id } })
        .then(() => {
          ElMessage.success({
            message: "密码已被重置",
            type: "success",
          });
        })
        .catch((e) => {
          ElMessage.error({
            message: e.msg,
            type: "error",
          });
        });
    },
    delete(id) {
      ax("delManager", { urlParam: { id } })
        .then(() => {
          let newTableData = [];
          for (let i in this.tableData) {
            if (id != this.tableData[i].id)
              newTableData.push(this.tableData[i]);
          }
          this.tableData = newTableData;
          ElMessage.success({
            message: "用户已删除",
            type: "success",
          });
        })
        .catch((e) => {
          ElMessage.error({
            message: e.msg,
            type: "error",
          });
        });
    },
  },
};
</script>

<style lang="less">
.systemManagement_ctrlCol {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.systemManagement_chooseSchool,
.systemManagement_searchCol {
  flex: 2;
}
.systemManagement_searchCol {
  display: flex;
  align-items: center;
}
.systemManagement_searchCol_input {
  width: 200px;
}
.systemManagement_searchCol_button {
  flex: 1;
  padding-left: 10px;
}
.systemManagement_functionCol {
  flex: 2;
  text-align: right;
}
.systemManagement_table {
}
</style>
