<template>
  <el-card class="box-card">
    <div class="topImg_othManageInfo">
      <div class="topImg_othManageInfoArea">
        <h4>{{this.submitData.title}}</h4>
        <el-upload
          action="https://up-z2.qiniup.com"
          list-type="text"
          :on-success="commonChange"
          :before-upload="getQNToken"
          :data="qiniuData"
          accept=".png,.jpg"
          :show-file-list="false"
        >
          <el-button
            size="small"
            type="primary"
            :loading="this.submitLoading"
          >点击上传</el-button>
          <template #tip>
            <div class="el-upload__tip">
              只能上传 jpg/png 文件，建议尺寸750*400，最多上传1张。
            </div>
          </template>
        </el-upload>
      </div>
      <div
        class="topImg_othManageImgPreview"
        :style="{backgroundImage:'url('+this.submitData.imgURL+')'}"
      />
    </div>
  </el-card>
</template>
<script>
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
export default {
  props: {
    originData: Object,
    uploadSuccess: Function,
  },
  data() {
    return {
      submitData: {
        id: "",
        imgURL: "",
        title: "",
        type: 0,
      },
      qiniuData: {
        token: "",
      },
      submitLoading: false,
    };
  },
  mounted() {
    this.dealOriginData(this.originData);
  },
  watch: {
    originData(newValue) {
      //   console.log(newValue);
      if (newValue.imgURL != this.submitData.imgURL)
        this.dealOriginData(newValue);
    },
  },
  methods: {
    getQNToken() {
      this.submitLoading = true;
      return ax("getqiniutoken")
        .then((res3) => {
          console.log(res3);
          this.qiniuData.token = res3.data;

          return true;
        })
        .catch((error) => {
          console.log(error);
          this.submitLoading = false;
          ElMessage.error("上传失败。");
          return false;
        });
    },
    commonChange(res) {
      // 执行数据库写入
      let submitJSON = {
        imgURL: "https://cdn.fryj.top/" + res.key,
        type:
          typeof this.submitData.type == "string"
            ? parseInt(this.submitData.type)
            : this.submitData.type,
      };
      if (this.submitData.id == 0) {
        ax("addSinBanner", {
          data: submitJSON,
        })
          .then((res) => {
            console.log(res);
            this.submitData.imgURL = submitJSON.imgURL;
            this.submitLoading = false;
            // this.$emit("uploadSuccess", {
            //   id: this.submitData.id,
            //   imgURL: submitJSON.imgURL,
            //   type: this.submitData.type,
            // });
          })
          .catch((error) => {
            console.log(error);
            this.submitLoading = false;
            ElMessage.error("上传失败。");
            return false;
          });
      } else {
        ax("editSinBanner", {
          data: submitJSON,
          urlParam: { id: this.submitData.id },
        })
          .then(() => {
            this.submitData.imgURL = submitJSON.imgURL;
            this.$emit("uploadSuccess", {
              id: this.submitData.id,
              imgURL: submitJSON.imgURL,
              type: this.submitData.type,
            });
            this.submitLoading = false;
          })
          .catch((error) => {
            console.log(error);
            ElMessage.error("上传失败。");
            this.submitLoading = false;
            return false;
          });
      }

      //   console.log(res);
    },
    dealOriginData(newValue) {
      console.log(newValue);
      this.submitData = {
        id: newValue.id,
        imgURL: newValue.imgURL,
        title: newValue.title,
        type: newValue.type,
      };
    },
  },
};
</script>
<style>
</style>
