<template>
  <main-layout>
    <div v-if="!(this.powerListCheck(this.allPower).allPowerCheck)">
      <el-alert
        title="您无权查看本页面。"
        type="warning"
        show-icon
        :closable="false"
      />
    </div>
    <div
      class="pages"
      v-loading="loading"
      v-if="this.powerCheck('获取自定义菜单列表')"
    >

      <el-card class="box-card">
        <div class="menu">
          <div class="mobile">
            <div class="phone">
              <div class="phone_head">
                <img src="@/assets/bg-mobile-head.png">
              </div>
              <div class="phone_info">
                <div class="phone_foot">
                  <div
                    class="first_menu"
                    v-for="(item,index) in menu"
                    :key="index"
                    @click="editMenu(item)"
                  >
                    <div class="second_menu">
                      <div
                        class="subBtn"
                        v-if="item.sub_button.length<5"
                        @click.stop="addSecMenu(item)"
                      >
                        <h4>+</h4>
                      </div>
                      <div
                        class="subBtn"
                        v-for="(el,i) in item.sub_button"
                        :key="index-i"
                        @click.stop="editMenu(el)"
                      >
                        <h4>{{el.name}}</h4>
                      </div>
                    </div>
                    <h3>{{item.name}}</h3>
                  </div>
                  <div
                    class="first_menu"
                    v-if="menu.length<3"
                    @click.stop="addFirstMenu"
                  >
                    <h3>+</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="info">
            <p>
              注意：所有自定义菜单名称都不能相同，相同会导致自定义菜单失效
            </p>
            <el-row class="info_row">
              <el-col
                class="info_tips"
                :span="6"
              >当前菜单</el-col>
              <el-col
                class="info_info"
                :span="18"
              >
                <el-input
                  v-model="edit_menu.name"
                  type="text"
                  style="max-width:500px"
                ></el-input>
                <el-button
                  type="danger"
                  size="mini"
                  @click="delMenu"
                  :disabled="delDisabled"
                >删除</el-button>
              </el-col>
            </el-row>
            <el-row class="info_row">
              <el-col
                class="info_tips"
                :span="6"
              >类型</el-col>
              <el-col
                class="info_info"
                :span="18"
              >
                <el-radio-group
                  v-model="edit_menu.type"
                  size="small"
                >
                  <!-- <el-radio
                    label="click"
                    border
                  >图文</el-radio> -->
                  <el-radio
                    label="view"
                    border
                  >链接</el-radio>
                </el-radio-group>
              </el-col>
            </el-row>
            <el-row
              class="info_row"
              v-if="edit_menu.type == 'click'"
            >
              <el-col
                class="info_tips"
                :span="6"
              >图文</el-col>
              <el-col
                class="info_info"
                :span="18"
              >
                <el-row>
                  <el-radio-group
                    v-model="edit_menu.key.MsgType"
                    size="small"
                  >
                    <!-- <el-radio
                      label="text"
                      border
                    >文本</el-radio> -->
                    <!-- <el-radio
                      label="news"
                      border
                    >图文</el-radio> -->
                  </el-radio-group>
                </el-row>
                <el-row
                  v-if="edit_menu.key.MsgType == 'text'"
                  style="padding:20px 0;"
                >
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="edit_menu.key.Content"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-row>
                <el-row
                  v-if="edit_menu.key.MsgType == 'news'"
                  style="padding:20px 0;"
                >
                  <div class="news_box">
                    <div class="news_list">
                      <div
                        @click="editNewsLi(e)"
                        class="news_li"
                        v-for="(e,index) in edit_menu.key.Articles"
                        :key="index"
                      >
                        <div
                          class="news_li_one"
                          v-if="index < 1"
                        >
                          <img
                            :src="e.PicUrl"
                            alt
                          >
                          <h4>{{e.Title}}</h4>
                        </div>
                        <div
                          class="news_lis"
                          v-if="index > 0"
                        >
                          <h4>{{e.Title}}</h4>
                          <div class="news_lis_img">
                            <img
                              :src="e.PicUrl"
                              alt
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        class="news_add"
                        v-if="edit_menu.key.Articles.length<8"
                        @click="addNews"
                      >新增</div>
                    </div>
                    <div class="news_edit">
                      <el-form
                        ref="form"
                        :model="news"
                        label-width="80px"
                      >
                        <el-form-item>
                          <el-button
                            type="danger"
                            size="mini"
                            @click="delNews"
                          >删除本条</el-button>
                        </el-form-item>
                        <el-form-item label="标题">
                          <el-input v-model="news.Title"></el-input>
                        </el-form-item>
                        <el-form-item label="描述">
                          <el-input
                            type="textarea"
                            v-model="news.Description"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="图片">
                          <el-upload
                            action="https://up-z2.qiniup.com"
                            list-type="text"
                            :on-success="commonChange"
                            :before-upload="getQNToken"
                            :data="qiniuData"
                            accept=".png,.jpg"
                            :show-file-list="false"
                          >
                            <el-button
                              size="small"
                              type="primary"
                              :loading="this.submitLoading"
                            >点击上传</el-button>
                            <template #tip>
                              <div class="el-upload__tip">
                                只能上传 jpg/png 文件，建议尺寸750*400，最多上传1张。
                              </div>
                            </template>
                          </el-upload>
                        </el-form-item>
                        <el-form-item label="跳转地址">
                          <el-input v-model="news.Url"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                </el-row>
              </el-col>
            </el-row>
            <el-row
              class="info_row"
              v-if="edit_menu.type == 'view'"
            >
              <el-col
                class="info_tips"
                :span="8"
              >链接</el-col>
              <el-col
                class="info_info"
                :span="16"
              >
                <el-input
                  placeholder="请输入内容"
                  v-model="edit_menu.url"
                >
                </el-input>
                <p>链接必须是正常链接，否则会导致编辑失败。正确链接例如：http://www.baidu.com</p>
              </el-col>
            </el-row>
          </div>
        </div>
        <el-row>
          <div
            class="editMenuBtn"
            @click="saveMenu"
          >
            <el-button type="primary">保存</el-button>
          </div>
        </el-row>
      </el-card>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/Main.vue";
import { powerCheck, powerListCheck } from "@/tools/util";
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      allPower: ["获取自定义菜单列表"],
      upload_addr: "https://cdn.fryj.top/",
      upload_url: "https://up-z2.qiniup.com",
      qiniuData: {
        token: "",
      },
      delDisabled: false,
      menu: [],
      edit_menu: {},
      news: {}, //编辑单条图文信息
      picList: [],
      loading: true,
      submitPICLoading: false,
    };
  },
  components: {
    MainLayout,
  },
  mounted() {
    // console.log("123132");
    ax("getMenuList")
      .then((res) => {
        console.log(res);
        console.log(res.data.menu.button);
        let thisMenuData = res.data.menu.button;
        if (thisMenuData == null) thisMenuData = [];
        for (let i in thisMenuData) {
          if (thisMenuData[i].key != "")
            thisMenuData[i].key = JSON.parse(thisMenuData[i].key);
          if (thisMenuData[i].sub_button) {
            for (let j in thisMenuData[i].sub_button) {
              if (thisMenuData[i].sub_button[j].key != "")
                thisMenuData[i].sub_button[j].key = JSON.parse(
                  thisMenuData[i].sub_button[j].key
                );
            }
          }
        }
        this.menu = thisMenuData;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        ElMessage.error("获取列表失败");
      });
  },
  methods: {
    powerCheck,
    powerListCheck,
    getQNToken() {
      this.submitPICLoading = true;
      return ax("getqiniutoken")
        .then((res3) => {
          console.log(res3);
          this.qiniuData.token = res3.data;
          return true;
        })
        .catch((error) => {
          console.log(error);
          this.submitPICLoading = false;
          ElMessage.error("上传失败。");
          return false;
        });
    },
    commonChange(e) {
      console.log(e);
      this.news.PicUrl = "https://cdn.fryj.top/" + e.key;
    },
    // upload(token, key, req, putExtra, next, error, complete) {
    //   const { file } = req;
    //   const config = {
    //     useCdnDomain: true,
    //     region: Qiniu.region.z2,
    //   };
    //   let observable = Qiniu.upload(file, key, token, putExtra, config);
    //   observable.subscribe(next, error, complete);
    // },
    // uploadRequest(type) {
    //   let vue = this;
    //   return function (req) {
    //     vue.upload(
    //       vue.quniuData.token,
    //       vue.quniuData.key,
    //       req,
    //       {
    //         fname: req.file.name,
    //         params: { "x:type": type },
    //         mimeType: [] || null,
    //       },
    //       (res) => {
    //         //请求成功 开始上传
    //       },
    //       (err) => {
    //         if (err) {
    //           vue.$message.warning(err);
    //         }
    //       },
    //       (res) => {
    //         //上传成功
    //         if (type == 1) {
    //           vue.picList[0] = { url: "", name: "" };
    //           vue.picList[0].url = vue.upload_addr + res.key;
    //           vue.news.PicUrl = vue.upload_addr + res.key;
    //         }
    //       }
    //     );
    //   };
    //   return false;
    // },
    beforeUpload(file) {
      this.quniuData.key = new Date().getTime() + file.name;
    },
    // getQiniuToken() {
    //   let vue = this;
    //   ServiceFactory.select("index")
    //     .on("getqiniutoken", this.$axios)
    //     .then((res) => {
    //       vue.quniuData.token = res.data.data;
    //     });
    // },

    editNewsLi(element) {
      console.log(element);
      this.news = element;
    },
    delNews() {
      this.edit_menu.key.ArticleCount--;
      let index = this.edit_menu.key.Articles.indexOf(this.news);
      this.edit_menu.key.Articles.splice(index, 1);
    },
    addNews() {
      this.edit_menu.key.ArticleCount++;
      this.picList = [];
      this.edit_menu.key.Articles.push({
        Description: "",
        PicUrl: "",
        Title: "",
        Url: "",
      });
    },
    delMenu() {
      this.menu.forEach((item, index) => {
        if (item.name == this.edit_menu.name && item.sub_button.length == 0) {
          this.menu.splice(index, 1);
        } else {
          item.sub_button.forEach((el, i) => {
            if (el.name == this.edit_menu.name) {
              this.menu[index].sub_button.splice(i, 1);
            }
          });
        }
      });
      this.edit_menu = {};
    },
    saveMenu() {
      console.log(this.menu);
      let data = JSON.parse(JSON.stringify(this.menu));
      data.forEach((item) => {
        if (item.key == "") {
          delete item.key;
        }
        if (item.key) {
          item.key = JSON.stringify(item.key);
        }

        if (item.type == "") {
          delete item.type;
        }
        if (!item.sub_button.length) {
          delete item.sub_button;
        } else {
          delete item.url;
          delete item.type;
          delete item.key;
          delete item.MsgType;
          item.sub_button.forEach((element) => {
            if (element.key.MsgType) {
              if (element.key) {
                element.key = JSON.stringify(element.key);
              }
            } else {
              delete element.key;
            }

            if (!element.sub_button || element.sub_button.length == 0) {
              delete element.sub_button;
            }
          });
        }
      });

      // let vue = this;
      let submitJSON = { button: data };
      ax("saveMenu", { data: submitJSON })
        .then((res3) => {
          console.log(res3);
          // this.qiniuData.token = res3.data;
          ElMessage.success("保存成功。");
          return true;
        })
        .catch((error) => {
          console.log(error);
          this.submitPICLoading = false;
          ElMessage.error("保存失败。");
          return false;
        });
      // ServiceFactory.select("index")
      //   .on("saveMenu", this.$axios, { button: data })
      //   .then((res) => {
      //     console.log(res);
      //   });
    },
    editMenu(element) {
      console.log(element);
      if (element.sub_button != undefined) {
        if (element.sub_button.length != 0) {
          this.delDisabled = true;
        } else {
          this.delDisabled = false;
        }
      } else {
        this.delDisabled = false;
      }
      this.edit_menu = element;
    },
    addFirstMenu() {
      this.menu.push({
        key: {
          MsgType: "",
          Articles: [
            {
              Title: "",
              Description: "",
              PicUrl: "",
              Url: "",
            },
          ],
        },
        MsgType: "",
        name: "菜单名称",
        sub_button: [],
        type: "",
      });
      this.editMenu(this.menu[this.menu.length - 1]);
    },
    addSecMenu(element) {
      //添加菜单
      element.sub_button.unshift({
        type: "",
        name: "子菜单名称",
        key: {
          MsgType: "",
          Content: "",
          ArticleCount: 1,
          Articles: [
            {
              Title: "",
              Description: "",
              PicUrl: "",
              Url: "",
            },
          ],
        },
        Content: "",
        MsgType: "",
      });
      this.editMenu(element.sub_button[0]);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
  },
};
</script>

<style lang="less">
.menu {
  display: flex;
  .mobile {
    width: 400px;
  }
  .info {
    flex: 1;
  }
}

.editMenuBtn {
  padding-top: 20px;
  text-align: center;
  width: 100%;
}

.news_lis {
  height: 60px;
  padding: 0 5px;
  display: flex;
  h4 {
    width: 140px;
    font-size: 14px;
    font-weight: 400;
    line-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .news_lis_img {
    width: 60px;
    text-align: center;
    line-height: 60px;
    img {
      margin-top: 10px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
  }
}
.news_li_one {
  width: 100%;
  height: 120px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  h4 {
    position: absolute;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    bottom: 0;
  }
}
.news_add {
  border: 1px solid #ccc;
  text-align: center;
}
.news_box {
  display: flex;
}
.news_list {
  width: 210px;
  //   border: 1px solid #ccc;
}
.news_li {
  border: 1px solid #ccc;
  width: 100%;
}
.news_edit {
  flex: 1;
}
.info_row {
  padding: 10px 0;
  line-height: 40px;
  display: flex;
  .info_tips {
    padding-right: 20px;
    width: 100px;
    text-align: right;
  }
  .info_info {
    flex: 1;
    .el-button {
      float: right;
    }
  }
}

.second_menu {
  position: absolute;
  bottom: 50px;
  width: 100%;
}
.subBtn {
  height: 50px;
  border: 0.5px solid #ccc;
  h4 {
    font-size: 14px;
    font-weight: 400;
  }
}
.first_menu {
  position: relative;
  flex: 1;
  text-align: center;
  line-height: 50px;
  border-right: 1px solid #ccc;
  h3 {
    font-size: 14px;
  }
  &:last-child {
    border: 0;
  }
}
.phone_foot {
  border-top: 1px solid #ccc;
  height: 50px;
  position: absolute;
  padding-left: 44px;
  width: 276px;
  display: flex;
  bottom: 0;
}

.phone {
  position: relative;
  margin: 0 auto;
  width: 320px;
  height: 568px;
  border: 1px solid #ccc;
  font-size: 0;
}
.phone_info {
  height: 504px;
  background: url("../../assets/bg-mobile-foot.png") no-repeat;
  background-position: bottom;
}
</style>