<template>
  <main-layout>
    <div v-if="!(this.powerListCheck(this.allPower).allPowerCheck)">
      <el-alert
        title="您无权查看本页面。"
        type="warning"
        show-icon
        :closable="false"
      />
    </div>
    <div
      class="pages"
      v-loading="loading"
      v-if="this.powerCheck('获取称号列表')"
    >
      <el-card class="box-card">
        <div class="title_ctrlCol">
          <div class="title_searchCol">
            <!-- <div class="title_searchCol_input">
              <el-input
                v-model="searchKeyWordInput"
                placeholder="请输入搜索内容"
                size="small"
              ></el-input>
            </div>
            <div class="title_searchCol_button">
              <el-button
                type="primary"
                size="small"
              >搜索</el-button>
            </div>-->
          </div>
          <div class="title_functionCol">
            <div class="title_searchCol_button">
              <el-button
                type="primary"
                size="small"
                @click="this.addVisible = true"
                v-if="this.powerCheck('添加称号')"
              >新增称号</el-button>
            </div>
          </div>
        </div>
        <div class="title_table">
          <el-table
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              label=" "
              width="80"
            >
              <template #default="scope">
                <span style="margin-left: 10px">{{ ((this.pageNum - 1) * this.pageSize) + (scope.$index + 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="称号名称"
            />
            <el-table-column label="称号类型">
              <template #default="scope">
                <span v-if="scope.row.type==1">自动获取</span>
                <span v-else>后台指定</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="score"
              label="最低分值(h)"
            >
              <template #default="scope">
                {{scope.row.score && scope.row.type == 1?(scope.row.score/3600).toFixed(1):"-"}}
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="状态"
            >
              <template #default="scope">
                <span v-if="scope.row.status == 1">运行</span>
                <span v-else>锁定</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"
            >
              <template #default="scope">
                <el-button
                  type="primary"
                  size="small"
                  @click="this.startEdit(scope.row.id)"
                  v-if="this.powerCheck('编辑称号详情')"
                >编辑</el-button>
                <el-popconfirm
                  confirmButtonText="确定"
                  cancelButtonText="取消"
                  icon="el-icon-info"
                  iconColor="red"
                  title="确定删除吗？"
                  @confirm="this.delete(scope.row.id)"
                >
                  <template #reference>
                    <el-button
                      type="danger"
                      size="small"
                      v-if="this.powerCheck('删除称号')"
                    >删除</el-button>
                  </template>
                </el-popconfirm>
              </template>

            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="this.sizeChange"
            @current-change="this.pageChange"
            :current-page="this.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="this.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.total"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <AddTitle
      :isVisible="this.addVisible"
      @closeAct="this.closeAdd"
      @addSuccess="this.addSuccess"
    />
    <EditTitle
      :isVisible="this.editVisible"
      :editTitleID="this.editIdx"
      @closeAct="this.closeEdit"
      @editSuccess="this.editSuccess"
    />
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/Main.vue";
import AddTitle from "./addTitle.vue";
import EditTitle from "./editTitle.vue";
import { powerCheck, powerListCheck } from "@/tools/util";
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 20,
      total: 1,
      loading: false,
      tableData: [],
      allPower: ["获取称号列表"],
      addVisible: false,
      editVisible: false,
      editIdx: 0,
    };
  },
  loading: false,
  components: {
    MainLayout,
    AddTitle,
    EditTitle,
  },
  created() {
    this.getData();
    // this.getQiniuToken();
  },
  methods: {
    powerCheck,
    powerListCheck,
    startAdd() {
      this.addVisible = true;
    },
    closeAdd() {
      this.addVisible = false;
    },
    addSuccess(newData) {
      console.log(newData);
      this.addVisible = false;
      this.tableData = [newData, ...this.tableData];
    },
    startEdit(idx) {
      console.log(idx);
      this.editIdx = idx;
      this.editVisible = true;
    },
    closeEdit() {
      this.editVisible = false;
    },
    editSuccess(newData) {
      console.log(newData);
      let newTableData = [...this.tableData];
      for (let i in newTableData) {
        if (newData.id == newTableData[i].id) newTableData[i] = newData;
      }
      this.tableData = newTableData;
      this.editVisible = false;
    },
    delete(id) {
      ax("deleteTitle", { urlParam: { id } })
        .then(() => {
          let newTableData = [];
          for (let i in this.tableData) {
            if (id != this.tableData[i].id)
              newTableData.push(this.tableData[i]);
          }
          this.tableData = newTableData;
          ElMessage.success({
            message: "称号已删除",
            type: "success",
          });
        })
        .catch((e) => {
          ElMessage.error({
            message: e.msg,
            type: "error",
          });
        });
    },
    sizeChange(e) {
      this.pageSize = e;
      this.getData();
    },
    pageChange(e) {
      this.pageNum = e;
      this.getData();
    },

    getData() {
      // console.log(e)
      this.loading = true;
      let queryObject = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      console.log(queryObject);
      if (this.searchText != "") {
        queryObject.searchText = this.searchText;
      }
      ax("getTitleList", { data: queryObject })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less">
.title_ctrlCol {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.title_chooseSchool,
.title_searchCol {
  flex: 2;
}
.title_searchCol {
  display: flex;
  align-items: center;
}
.title_searchCol_input {
  width: 200px;
}
.title_searchCol_button {
  flex: 1;
  padding-left: 10px;
}
.title_functionCol {
  flex: 2;
  text-align: right;
}
.title_table {
}
</style>
