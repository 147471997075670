<template>
  <main-layout>
    <div v-if="!(this.powerListCheck(this.allPower).allPowerCheck)">
      <el-alert
        title="您无权查看本页面。"
        type="warning"
        show-icon
        :closable="false"
      />
    </div>
    <div
      class="pages"
      v-loading="loading"
      v-if="this.powerCheck('获取志愿者列表')"
    >
      <el-card class="box-card">
        <div class="volunteer_ctrlCol">
          <div class="volunteer_chooseSchool">

          </div>
          <div class="volunteer_searchCol">
            <div class="volunteer_searchCol_input">
              <el-input
                v-model="searchText"
                placeholder="请输入搜索内容"
                size="small"
              ></el-input>
            </div>
            <div class="volunteer_searchCol_button">
              <el-button
                type="primary"
                size="small"
                @click="this.getData()"
              >搜索</el-button>
            </div>
          </div>
          <div class="volunteer_functionCol">
            <div class="volunteer_searchCol_button">
              <el-button
                type="primary"
                size="small"
                v-if="this.powerCheck('导出志愿者列表')"
              >导出</el-button>
            </div>
          </div>
        </div>
        <div class="volunteer_table">
          <el-table
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              label=" "
              width="120"
            >
              <template #default="scope">
                <span style="margin-left: 10px">{{ ((this.pageNum - 1) * this.pageSize) + (scope.$index + 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="姓名"
            />
            <el-table-column
              prop="name"
              label="性别"
            >
              <template #default="scope">
                <span v-if="scope.row.gender==1">男</span>
                <span v-else-if="scope.row.gender==2">女</span>
                <span v-else>未知</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="创建日期"
              sortable
            >
              <template #default="scope">
                {{ moment(scope.row.createdAt*1000).format('YYYY-MM-DD') }}
                <br />
                {{ moment(scope.row.createdAt*1000).format('HH:mm:ss') }}
              </template>
            </el-table-column>
            <el-table-column
              prop="org.name"
              label="所属单位"
              sortable
            />
            <el-table-column
              prop="major"
              label="专业"
            />
            <el-table-column
              prop="hobby"
              label="爱好"
            />
            <el-table-column
              prop="address"
              label="称号"
              sortable
            >
              <template #default="scope">
                {{scope.row.show_title}}
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="志愿服务时常(h)"
              sortable
            >
              <template #default="scope">
                {{scope.row.service_time?scope.row.service_time/60/60:0}}
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"
            >
              <template #default="scope">
                <el-button
                  type="success"
                  size="small"
                  @click="this.displayInfo(scope.row.id)"
                >详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="this.sizeChange"
            @current-change="this.pageChange"
            :current-page="this.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="this.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.total"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <el-drawer
      title="我是标题"
      v-model="showInfo"
      direction="rtl"
      destroy-on-close
    >
      <div class="func">
        <!-- <span>我来啦!</span> -->
        <DisPlaySinItem
          v-for="ii in this.disMoreItem"
          :key="ii.key"
          :keyName="ii.name"
          :keyValue="this.disMoreInfo[ii.key]"
        />
        <el-row :gutter="20">
          <el-col
            :span="6"
            class="itemRight"
          >身份证正面:</el-col>
          <el-col
            :span="18"
            class="itemLeft"
          >
            <div class="imgBlock">
              <el-image>
                <template #error>
                  <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </template>
              </el-image>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col
            :span="6"
            class="itemRight"
          >身份证反面:</el-col>
          <el-col
            :span="18"
            class="itemLeft"
          >
            <div class="imgBlock">
              <el-image>
                <template #error>
                  <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </template>
              </el-image>
            </div>
          </el-col>
        </el-row>
        <el-divider />
        <el-row :gutter="20">
          <el-col
            :span="6"
            class="itemRight"
          >给与称号:</el-col>
          <el-col
            :span="18"
            class="itemLeft"
          >
            <el-select
              v-model="giveTitleIdx"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in giveTitle"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col
            :span="6"
            class="itemRight"
          >变更组织/学校:</el-col>
          <el-col
            :span="18"
            class="itemLeft"
          >
            <el-select
              v-model="newOrgIDList"
              placeholder="请选择"
              size="small"
              filterable
            >
              <el-option
                v-for="item in allOrgs"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col
            :span="6"
            class="itemRight"
          >认证状态:</el-col>
          <el-col
            :span="18"
            class="itemLeft"
          >
            <div>
              <el-radio
                v-model="this.disMoreInfo.status"
                label="1"
              >已认证</el-radio>
              <el-radio
                v-model="this.disMoreInfo.status"
                label="0"
              >未认证</el-radio>
            </div>
          </el-col>
        </el-row>
        <el-button
          type="primary"
          size="small"
          @click="this.submitData()"
          v-if="this.powerCheck('编辑志愿者')"
        >提交</el-button>
      </div>
      <br />
      <br />
    </el-drawer>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/Main.vue";
import { powerCheck, powerListCheck } from "@/tools/util";
import { nationList } from "@/components/nationList";
import DisPlaySinItem from ".//disPlaySinItem";
import moment from "moment";
import { ax } from "@/service/http";
// import moment from "moment";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 20,
      total: 1,
      loading: false,
      tableData: [],
      allPower: ["获取志愿者列表"],
      searchText: "",
      showInfo: false,
      disMoreItem: [
        { name: "姓名", key: "name" },
        { name: "服务时长", key: "volunteerTime" },
        { name: "使用称号", key: "showTitleName" },
        { name: "身份证号", key: "idCard" },
        { name: "注册时间", key: "creatTime" },
        { name: "出生年月", key: "birthday" },
        { name: "id", key: "id" },
        { name: "昵称", key: "nickname" },
        { name: "爱好", key: "hobby" },
        // { name: "毕业院校", key: "orgName" },
        { name: "专业", key: "major" },
        { name: "电话", key: "tel" },
        { name: "民族", key: "nation" },
        { name: "地区", key: "area" },
        { name: "组织/学校", key: "orgName" },
      ],
      disMoreID: 0,
      disMoreInfo: {},
      disMoreInfoLoading: false,
      getInfoLoading: false,
      giveTitle: [],
      giveTitleIdx: "",
      allOrgs: [],
      newOrgIDList: "",
      editLoading: false,
    };
  },

  loading: false,
  components: {
    MainLayout,
    DisPlaySinItem,
  },
  created() {
    this.getData();
    this.getAllOrgs();
    this.getTitleList();
    // this.getQiniuToken();
  },
  methods: {
    powerCheck,
    powerListCheck,
    moment,
    // getUserTitle(titleList) {
    //   console.log(titleList);
    //   let name = "";
    //   this.newTitleOptions.forEach((item) => {
    //     if (row.show_title == item.id) {
    //       name = item.name;
    //     }
    //   });
    //   return name;
    // },
    getTitleList() {
      ax("getSimpleTitleList")
        .then((res) => {
          console.log(res);
          let thisTitleList = [];
          for (let i in res.data) {
            thisTitleList.push({
              value: res.data[i].id,
              label: res.data[i].name,
            });
          }
          console.log(thisTitleList);
          this.giveTitle = thisTitleList;
        })
        .catch((error) => {
          console.log(error);
          // sessionStorage.clear();
          ElMessage.error(
            "获取称号列表失败，您可能无法授予称号，请尝试刷新后重试。"
          );
          //   return false;
        });
    },
    getAllOrgs() {
      ax("getOrgListSimple")
        .then((res) => {
          console.log(res);
          let thisOrgList = [];
          let reqList = res.data.list;
          for (let i in reqList) {
            let addressCode = [];
            let addressWord = [];
            if (reqList[i].provinceIndex) {
              addressCode.push(reqList[i].provinceIndex);
              addressWord.push(reqList[i].province);
              if (reqList[i].cityIndex) {
                addressCode.push(reqList[i].cityIndex);
                addressWord.push(reqList[i].city);
                if (reqList[i].countyIndex) {
                  addressCode.push(reqList[i].countyIndex);
                  addressWord.push(reqList[i].county);
                }
              }
            }
            thisOrgList.push({
              value: reqList[i].id,
              label: reqList[i].name,
              addressCode,
              addressWord,
              type: reqList[i].type,
              schoolType: reqList[i].schoolType,
            });
          }
          this.allOrgs = thisOrgList;
          //   setQNToken(res3.data);
          //   this.qiniuData.token = res3.data;
          //   return true;
        })
        .catch((error) => {
          console.log(error);
          // sessionStorage.clear();
          ElMessage.error(
            "获取组织列表失败，您可能无法选择参与范围，请尝试刷新后重试。"
          );
          //   return false;
        });
    },
    sizeChange(e) {
      this.pageSize = e;
      this.getData();
    },
    pageChange(e) {
      this.pageNum = e;
      this.getData();
    },
    getData() {
      // console.log(nationList);
      // console.log(e)
      this.loading = true;
      this.giveTitleIdx = "";
      let queryObject = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      console.log(queryObject);
      if (this.searchText != "") {
        queryObject.searchText = this.searchText;
      }
      ax("getVolunteerList", { data: queryObject })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    displayInfo(id) {
      this.giveTitleIdx = "";
      this.newOrgIDList = "";
      this.showInfo = true;
      this.getInfoLoading = true;
      ax("getVolunteer", { urlParam: { id } })
        .then((res) => {
          console.log(res);

          if (res.data.nation) res.data.nation = nationList[res.data.nation];
          if (res.data.volunteerTime) {
            res.data.volunteerTime =
              (res.data.volunteerTime / 60 / 60).toFixed(1) + "小时";
          } else {
            res.data.volunteerTime = "0.0小时";
          }
          if (res.data.orgID) {
            res.data.orgName = res.data.org.name;
            res.data.area = res.data.org.province +'-'+ res.data.org.city +'-'+res.data.org.county ;

          }
          console.log(res.data.status);
          if (!res.data.status) {
            res.data.status = "0";
          } else if (res.data.status == 2) {
            res.data.status = "0";
          } else {
            res.data.status = res.data.status + "";
          }
          res.data.creatTime = moment(res.data.createdAt * 1000).format(
            "YYYY-MM-DD"
          );
          if (res.data.showTitleID)
            res.data.showTitleName = res.data.showTitle.name;
          let disMoreInfo = {
            ...res.data,
          };
          this.disMoreInfo = disMoreInfo;
          this.disMoreID = res.data.id;

          // this.tableData = res.data.list;
          // this.total = res.data.total;
          // this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitData() {
      this.editLoading = true;
      let submitData = {
        status: this.disMoreInfo.status == "0" ? 2 : 1,
      };
      if (this.giveTitleIdx != "") {
        submitData.addTitleID = parseInt(this.giveTitleIdx);
      }
      if (this.newOrgIDList != "") {
        submitData.orgID = parseInt(this.newOrgIDList);
        submitData.graduation = "";
        for (let i in this.allOrgs) {
          if (this.allOrgs[i].value == submitData.orgID)
            submitData.graduation = this.allOrgs[i].label;
        }
        console.log(submitData.graduation);
      }

      console.log(submitData);
      console.log(this.disMoreID);
      //addTitleID(submitData)
      //
      ax("editVolunteer", {
        data: submitData,
        urlParam: { id: this.disMoreID },
      })
        .then((res) => {
          console.log(res);
          ElMessage.success("编辑成功。");
          this.editLoading = false;
          this.getData();
          // let thisTitleList = [];
          // for (let i in res.data) {
          //   thisTitleList.push({
          //     value: res.data[i].id,
          //     label: res.data[i].name,
          //   });
          // }
          // console.log(thisTitleList);
          // this.giveTitle = thisTitleList;
        })
        .catch((error) => {
          console.log(error);
          this.editLoading = false;
          // sessionStorage.clear();
          ElMessage.error("编辑失败。");
          //   return false;
        });
    },
  },
};
</script>

<style lang="less">
.volunteer_ctrlCol {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.volunteer_chooseSchool,
.volunteer_searchCol {
  flex: 2;
}
.volunteer_searchCol {
  display: flex;
  align-items: center;
}
.volunteer_searchCol_input {
  width: 200px;
}
.volunteer_searchCol_button {
  flex: 1;
  padding-left: 10px;
}
.volunteer_functionCol {
  flex: 2;
  text-align: right;
}
.volunteer_table {
}
.el-drawer__body {
  overflow: auto;
}
.func {
  width: 100%;
  text-align: center;
  .el-row {
    margin-bottom: 8px;
  }
}
.imgBlock {
  .el-image {
    min-width: 300px;
    min-height: 200px;
    background-color: #f5f7fa;
    font-size: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 200px;
    color: #909399;
  }
}
</style>
