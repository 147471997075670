<template>
  <main-layout>
    <div v-if="!(this.powerListCheck(this.allPower).allPowerCheck)">
      <el-alert
        title="您无权查看本页面。"
        type="warning"
        show-icon
        :closable="false"
      />
    </div>
    <div
      class="pages"
      v-loading="loading"
    >
      <el-row :gutter="20">
        <el-col
          :span="24"
          v-if="this.powerCheck('主页数据分析')"
        >
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span>账户整体情况</span>
              </div>
            </template>
            <div class="home_titleStatistics">
              <div class="home_sinTitleStatistics">
                <div class="home_sinTitleStatisticsTitle">志愿者</div>
                <div class="home_sinTitleStatisticsData">{{this.thisData.volunteerCount}}</div>
              </div>

              <div class="home_sinTitleStatistics">
                <div class="home_sinTitleStatisticsTitle">组织数量</div>
                <div class="home_sinTitleStatisticsData">{{this.thisData.orgCount}}</div>
              </div>

              <div class="home_sinTitleStatistics">
                <div class="home_sinTitleStatisticsTitle">进行中的活动</div>
                <div class="home_sinTitleStatisticsData">{{this.thisData.activityCount}}</div>
              </div>

              <div class="home_sinTitleStatistics">
                <div class="home_sinTitleStatisticsTitle">进行中的问答</div>
                <div class="home_sinTitleStatisticsData">{{this.thisData.questionnaireCount}}</div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col
          :span="12"
          v-if="this.powerCheck('获取活动列表')"
        >
          <el-card class="box-card">
            <!-- <template #header>
              <div class="card-header">
                <span>最新活动</span>
              </div>
            </template> -->
            <div class="home_contentStatistics">
              <el-table
                :data="actData"
                style="width: 100%"
              >
                <el-table-column
                  prop="name"
                  label="最新活动"
                />
                <el-table-column
                  prop="joinVolunteerCount"
                  label="参与人数"
                  width="120px"
                />
              </el-table>
            </div>
          </el-card>
        </el-col>
        <el-col
          :span="12"
          v-if="this.powerCheck('获取内容列表')"
        >
          <el-card class="box-card">
            <!-- <template #header>
              <div class="card-header">
                <span>最新内容</span>
              </div>
            </template> -->
            <div class="home_contentStatistics">
              <el-table
                :data="conData"
                style="width: 100%"
              >
                <el-table-column
                  prop="title"
                  label="最新内容"
                />
                <el-table-column
                  prop="pubDate"
                  label="发表时间"
                  width="180px"
                >
                  <template #default="scope">
                    {{moment(scope.row.pubDate*1000).format('YYYY-MM-DD')}}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
        <el-col
          :span="24"
          v-if="this.powerCheck('主页数据分析')"
        >
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span>概况预览</span>
              </div>
            </template>
            <div
              class="home_chart"
              id="indexChart"
            >

            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import { powerCheck, powerListCheck } from "@/tools/util";
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
import moment from "moment";
import * as echarts from "echarts";
export default {
  loading: false,
  data() {
    return {
      allPower: ["获取活动列表", "获取内容列表"],
      thisData: {
        activityCount: "-",
        orgCount: "-",
        questionnaireCount: "-",
        volunteerCount: "-",
      },
      actData: [],
      conData: [],
    };
  },
  components: {
    MainLayout,
  },
  created() {
    this.getData();
    // this.getOrg();
    // this.getActive();
    // this.getQuestion();
  },
  methods: {
    moment,
    powerCheck,
    powerListCheck,
    getData() {
      ax("analysis")
        .then((res) => {
          console.log(res);
          this.thisData = res.data;
          // this.tplList = res.data;
          // if (this.sendData.type == "6") {
          //   this.volunteerList = res.data;
          // } else {
          //   this.peopleNum = res.data;
          // }
        })
        .catch((err) => {
          console.log(err);
          ElMessage.error(err.msg);
        });
      ax("getactivityList", { data: { pageNum: 1, pageSize: 5 } })
        .then((res) => {
          console.log(res);
          this.actData = res.data.list;
          // this.tplList = res.data;
          // if (this.sendData.type == "6") {
          //   this.volunteerList = res.data;
          // } else {
          //   this.peopleNum = res.data;
          // }
        })
        .catch((err) => {
          console.log(err);
          ElMessage.error(err.msg);
        });
      ax("getContentList", { data: { pageNum: 1, pageSize: 5 } })
        .then((res) => {
          console.log(res);
          this.conData = res.data.list;
          // this.tplList = res.data;
          // if (this.sendData.type == "6") {
          //   this.volunteerList = res.data;
          // } else {
          //   this.peopleNum = res.data;
          // }
        })
        .catch((err) => {
          console.log(err);
          ElMessage.error(err.msg);
        });
      ax("getIndexANS", {
        data: {
          startTime: moment().startOf("day").subtract(30, "d").valueOf() / 1000,
          endTime: moment().startOf("day").valueOf() / 1000,
        },
      })
        .then((res) => {
          console.log(res);
          let resDataIdx = {};
          let xData = [];
          let volunteerCount = [];
          for (let i in res.data) {
            resDataIdx[res.data[i].date] = res.data[i].volunteerCount;
            xData.push(res.data[i].date);
            // volunteerCount.push(res.data[i].volunteerCount);
          }
          xData.sort();
          console.log(xData);
          for (let i in xData) {
            volunteerCount.push(resDataIdx[xData[i]]);
          }
          let myChart = echarts.init(document.getElementById("indexChart"));
          // 绘制图表
          myChart.setOption({
            title: { text: "用户量" },
            tooltip: {},
            xAxis: {
              data: xData,
            },
            yAxis: {},
            series: [
              {
                name: "用户量",
                type: "line",
                data: volunteerCount,
              },
            ],
          });
          // if(this.editID ) {}
        })
        .catch((err) => {
          console.log(err);
          ElMessage.error("获取统计信息失败。");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.home_titleStatistics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: space-around;
  text-align: center;
  .home_sinTitleStatistics {
    height: 60px;
    border-left: 1px solid #ccc;
    flex: 1;
    .home_sinTitleStatisticsTitle {
      // height: 40px;
      // font-size: 22px;
      // line-height: 40px;
    }
    .home_sinTitleStatisticsData {
      height: 40px;
      font-size: 22px;
      line-height: 40px;
    }
  }
  .home_sinTitleStatistics:first-child {
    border: 0;
  }
}
.home_contentStatistics {
  min-height: 250px;
}
.home_chart {
  min-height: 250px;
}
.el-col {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>