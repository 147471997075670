<template>
  <div>
    <el-card class="box-card">
      <div class="cardBtn">
        <el-row :gutter="20">
          <el-col
            :span="6"
            v-for="(item,index) in tplList"
            :key="index"
          >
            <el-card
              shadow="hover"
              class="msgBox"
            >
              <h4>{{item.title}}</h4>
              <hr>
              <p class="msgContent">消息内容：{{item.content}}</p>
              <p class="msgEg">消息案例：{{item.example}}</p>
              <el-button
                type="primary"
                size="mini"
                @click="changeTpl(item)"
              >选择</el-button>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-dialog
      title="消息模板"
      v-model="dialogVisible"
      width="80%"
    >
      <el-form
        ref="form"
        :model="editMsgTpl"
        label-width="100px"
      >
        <el-form-item label="模板名称">
          <el-input
            disabled
            v-model="editMsgTpl.title"
          ></el-input>
        </el-form-item>
        <el-form-item label="模板案例">
          <el-input
            disabled
            v-model="editMsgTpl.content"
            type="textarea"
            :rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item label="发送对象">
          <el-radio-group v-model="sendData.type">
            <el-radio-button label="1">所选组织</el-radio-button>
            <el-radio-button label="2">所有校园大使</el-radio-button>
            <el-radio-button label="3">选择活动</el-radio-button>
            <el-radio-button label="4">选择问答</el-radio-button>
            <el-radio-button label="5">所有志愿者</el-radio-button>
            <el-radio-button label="6">指定志愿者</el-radio-button>
            <el-radio-button label="7">指定爱好</el-radio-button>
            <el-radio-button label="8">指定专业</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-show="sendData.type == '1'"
          label="选择组织"
        >
          <el-select
            v-model="sendData.id"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in orgList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-show="sendData.type == '3'"
          label="选择活动"
        >
          <el-select
            v-model="sendData.id"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in activeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-show="sendData.type == '4'"
          label="选择问答"
        >
          <el-select
            v-model="sendData.id"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in questionList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-show="sendData.type == '6'"
          label="选择志愿者"
        >
          <el-row>
            <el-input
              style="width:200px"
              v-model="searchVolunteer"
              placeholder="请输入信息"
            ></el-input>
            <el-button @click="serachInfo">查询</el-button>
          </el-row>
          <el-row style="padding-top:10px;">
            <el-table
              :data="volunteerList"
              border
              style="width: 100%"
              highlight-current-row
              @current-change="handleCurrentChange"
            >
              <el-table-column
                type="index"
                width="50"
              ></el-table-column>
              <el-table-column
                property="name"
                label="姓名"
              ></el-table-column>
              <el-table-column
                property="nickname"
                label="昵称"
              ></el-table-column>
              <el-table-column
                property="tel"
                label="电话"
              ></el-table-column>
              <el-table-column
                property="major"
                label="专业"
              ></el-table-column>
              <el-table-column
                property="hobby"
                label="爱好"
              ></el-table-column>
            </el-table>
          </el-row>
        </el-form-item>
        <el-form-item
          v-show="sendData.type == '7'||sendData.type == '8'"
          label="选取情况"
        >
          <el-row>
            <el-input
              style="width:200px"
              v-model="searchVolunteer"
              placeholder="请输入信息"
            ></el-input>
            <el-button @click="serachInfo">查询</el-button>
            <span>
              符合当前条件的共有
              <strong>{{peopleNum}}</strong>人
            </span>
          </el-row>
        </el-form-item>
        <el-form-item label="目标URL">
          <el-input v-model="sendData.url"></el-input>
        </el-form-item>
        <el-form-item label="变量提示">
          <p>以下内容可使用的变量：1.name(姓名)2.nickname(昵称)3.date(日期)4.time(时间)5.datetime(日期时间) **变量请使用双$符号包裹，变量不能连写(eg:你好，$$name$$,请在$$datetime$$参加活动)**</p>
        </el-form-item>
        <el-form-item
          v-for="(val,key) in editMsgTplParam"
          :key="key"
          :label="key"
        >
          <el-input
            v-model="sendData[key]"
            type="text"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="saveMsgTpl"
          >确 定</el-button>
        </span>
      </template>

    </el-dialog>
  </div>
</template>
<script>
// import ServiceFactory from "@/service/serviceFactory.js";
export default {
  data() {
    return {
      dialogVisible: false,
      tplList: [],
      editMsgTplParam: {},
      editMsgTpl: {},
      sendData: {
        type: "6",
      },
      peopleNum: 0,
      orgList: [],
      activeList: [],
      questionList: [],
      searchVolunteer: "",
      volunteerList: [],
      currentRow: null,
    };
  },
  created() {
    this.getData();
    this.getOrg();
    this.getActive();
    this.getQuestion();
  },
  methods: {
    serachInfo() {
      // // let vue = this;
      // ServiceFactory.select("index")
      //   .on("getMsgCheck", this.$axios, {
      //     type: parseInt(this.sendData.type) - 5,
      //     value: this.searchVolunteer,
      //   })
      //   .then((res) => {
      //     if (vue.sendData.type == "6") {
      //       vue.volunteerList = res.data.data;
      //     } else {
      //       vue.peopleNum = res.data.data;
      //     }
      //   });
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    saveMsgTpl() {
      let data = {};
      data = JSON.parse(JSON.stringify(this.sendData));

      if (data.type == undefined) {
        this.$message.error("请选择发送对象");
        return;
      }

      if (data.type == "1" || data.type == "3" || data.type == "4") {
        if (data.id == undefined) {
          this.$message.error("请选择发送对象");
          return;
        }
      }

      data.id = data.id ? parseInt(data.id) : 0;
      data.type = parseInt(data.type);
      data.data = {};
      let dataKeys = Object.keys(this.editMsgTplParam);
      for (let i = 0; i < dataKeys.length; i++) {
        data.data[dataKeys[i]] = {};
        data.data[dataKeys[i]].value = " " + data[dataKeys[i]];
        data.data[dataKeys[i]].color = "#ff7302";
      }
      data.template_id = this.editMsgTpl.template_id;
      // console.log(this.editMsgTpl, this.editMsgTplParam);
      // return;
      if (data.type == "6") {
        if (this.currentRow) {
          data.id = this.currentRow.id;
        } else {
          this.$message.error("请选择发送对象");
          return;
        }
      }
      if (data.type == "7" || data.type == "8") {
        if (this.peopleNum) {
          data.value = this.searchVolunteer;
        } else {
          this.$message.error("请选择发送对象");
          return;
        }
      }
      // let vue = this;
      // ServiceFactory.select("index")
      //   .on("sendMsgTpl", this.$axios, data)
      //   .then((res) => {
      //     console.log(res);
      //     if (res.data.msg == "成功") {
      //       vue.dialogVisible = false;
      //       vue.searchVolunteer = "";
      //       vue.peopleNum = 0;
      //       vue.currentRow = null;
      //       vue.currentRow = [];
      //     }
      //   });
    },
    changeTpl(element) {
      this.editMsgTplParam = {};
      console.log(this.sendData);
      this.editMsgTpl = Object.assign(this.editMsgTpl, element);
      this.dialogVisible = true;
      this.editMsgTpl.content.replace(/{{(.*?)\.DATA}}/g, ($0, $1) => {
        this.editMsgTplParam[$1] = "";
        return $0;
      });
    },
    getData() {
      // // let vue = this;
      // console.log(1);
      // ServiceFactory.select("index")
      //   .on("getMsgTpl", this.$axios, {})
      //   .then((res) => {
      //     vue.tplList = res.data.data.template_list;
      //   });
    },
    getOrg() {
      // // let vue = this;
      // vue.orgList = [];
      // ServiceFactory.select("index")
      //   .on("getOrgList", this.$axios, {
      //     pageSize: 9999,
      //     pageNum: 1,
      //     searchText: "",
      //   })
      //   .then((res) => {
      //     for (const key in res.data.data.orgs) {
      //       res.data.data.orgs[key].childrens = [];
      //       for (const i in res.data.data.orgs[key].children) {
      //         res.data.data.orgs[key].childrens.push(
      //           res.data.data.orgs[key].children[i]
      //         );
      //       }
      //       vue.orgList.push(res.data.data.orgs[key]);
      //     }
      //   });
    },
    getActive() {
      // // let vue = this;
      // ServiceFactory.select("index")
      //   .on("getactivityList", this.$axios, {
      //     pageSize: 9999,
      //     pageNum: 1,
      //     searchText: "",
      //   })
      //   .then((res) => {
      //     let data = res.data.data;
      //     vue.activeList = data.activitys;
      //   });
    },
    getQuestion() {
      // // let vue = this;
      // ServiceFactory.select("index")
      //   .on("getQuestionnaireList", this.$axios, {
      //     searchText: "",
      //     pageSize: 9999,
      //     pageNum: 1,
      //   })
      //   .then((res) => {
      //     let data = res.data.data.questionnaires;
      //     for (const key in data) {
      //       vue.questionList.push(data[key]);
      //     }
      //   });
    },
  },
};
</script>
<style lang="less">
.msgContent {
  font-size: 14px;
  color: #a3a3a3;
  padding: 5px 0;
}
.msgEg {
  padding-bottom: 20px;
}
.msgBox {
  h4 {
    padding-bottom: 5px;
  }
}
.cardIn {
  max-width: 220px;
}
.cardBtn {
  padding-bottom: 20px;
}
</style>
