let url = ""

export default {
    hello: {
        url: '/api/hello',
        method: 'get',
    },
    exChangePassword: {

    },
    //主页
    // analysis: (http, param) => {
    //     return http.get(url + '/api/analysis', param)
    // },
    analysis: {
        url: '/api/analysis',
        method: 'get',
    },
    getIndexANS: {
        url: '/api/volunteer/analysis',
        method: 'get',
    },
    getvolunteerAnalysis: (http, param) => {
        return http.get(url + `/api/volunteer/analysis${param}`)
    },
    getorgAnalysis: (http, param) => {
        return http.get(url + `/api/org/analysis${param}`)
    },
    //保存内容
    content: (http, param) => {
        return http.post(url + '/api/content', param)
    },
    //获取content列表
    // getContentList: (http, param) => {
    //     return http.post(url + '/api/content/search', param)
    // },
    getContentList: {
        url: '/api/content/list',
        method: 'get',
    },
    addContent: {
        url: '/api/content',
        method: 'post',
    },
    editContent: {
        url: '/api/content/{id}',
        method: 'put',
    },
    deleteContent: {
        url: '/api/content/{id}',
        method: 'delete',
    },
    getContentInfo: {
        url: '/api/content/{id}',
        method: 'get',
    },
    changeContentStatus: {
        url: '/api/content/{id}/examine',
        method: 'put',
    },
    //获取单条content
    getIndexContent: (http, param) => {
        return http.get(url + `/api/content/${param}`)
    },
    // 删除单条content
    delIndexContent: (http, param) => {
        return http.delete(url + `/api/content/${param}`)
    },
    ContentExamine: (http, param, params) => {
        return http.put(url + `/api/content/${param}/examine`, params)
    },
    // 活动activity 
    activity: (http, param) => {
        return http.post(url + '/api/activity', param)
    },
    getactivityList: {
        url: '/api/activity/list',
        method: 'get',
    },
    getActivity: {
        url: '/api/activity/{id}',
        method: 'get',
    },
    editActivity: {
        url: '/api/activity/{id}',
        method: 'put',
    },
    delActivity: {
        url: '/api/activity/{id}',
        method: 'delete',
    },
    // getactivityList: (http, param) => {
    //     return http.post(url + '/api/activity/search', param)
    // },
    setActivityList: {
        url: '/api/activity',
        method: 'post',
    },
    getActivityUserList: {
        url: '/api/activity/{id}/join',
        method: 'get',
    },
    getIndexactivity: (http, param) => {
        return http.get(url + `/api/activity/${param}`)
    },
    delIndexactivity: (http, param) => {
        return http.delete(url + `/api/activity/${param}`)
    },
    getActiveVollunteer: (http, param, params) => {
        return http.post(url + `/api/activity/${param}/join`, params)
    },
    //结算活动
    finishIndexactivity: (http, param, params) => {
        return http.post(url + `/api/activity/${param}/finish `, params)
    },
    // 任务task
    task: (http, param) => {
        return http.post(url + '/api/task', param)
    },
    getTaskList: (http, param) => {
        return http.post(url + '/api/task/search', param)
    },
    getIndexTask: (http, param) => {
        return http.get(url + `/api/task/${param}`)
    },
    delIndexTask: (http, param) => {
        return http.delete(url + `/api/task/${param}`)
    },
    replyTask: (http, param) => {
        return http.post(url + `/api/task/reply`, param)
    },
    // 称号title
    title: (http, param) => {
        return http.post(url + '/api/title', param)
    },
    addTitle: {
        url: '/api/title',
        method: 'post',
    },
    getTitleList: {
        url: '/api/title/list',
        method: 'get',
    },
    getSimpleTitleList: {
        url: '/api/title/simplelist',
        method: 'get',
    },
    getTitleInfo: {
        url: '/api/title/{id}',
        method: 'get',
    },
    editTitle: {
        url: '/api/title/{id}',
        method: 'put',
    },
    deleteTitle: {
        url: '/api/title/{id}',
        method: 'delete',
    },
    // getTitleList: (http, param) => {
    //     return http.post(url + '/api/title/search', param)
    // },
    getIndexTitle: (http, param) => {
        return http.get(url + `/api/title/${param}`)
    },
    delIndexTitle: (http, param) => {
        return http.delete(url + `/api/title/${param}`)
    },
    // 证书 certificate
    certificate: (http, param) => {
        return http.post(url + '/api/certificate', param)
    },
    getCertificateList: {
        url: '/api/certificate/list',
        method: 'get',
    },
    getCertificateListSimple: {
        url: '/api/certificate/simplelist',
        method: 'get',
    },
    addCertificateList: {
        url: '/api/certificate',
        method: 'post',
    },
    getCertificateInfo: {
        url: '/api/certificate/{id}',
        method: 'get',
    },
    editCertificateList: {
        url: '/api/certificate/{id}',
        method: 'put',
    },
    delCertificateList: {
        url: '/api/certificate/{id}',
        method: 'delete',
    },

    // getCertificateList: (http, param) => {
    //     return http.post(url + '/api/certificate/search', param)
    // },
    // getIndexCertificate: (http, param) => {
    //     return http.get(url + `/api/certificate/${param}`)
    // },
    // delIndexCertificate: (http, param) => {
    //     return http.delete(url + `/api/certificate/${param}`)
    // },
    // 组织 orgs
    // org: (http, param) => {
    //     return http.post(url + '/api/org', param)
    // },
    getOrgList: {
        url: '/api/org/list',
        method: 'get',
    },
    getOrg: {
        url: '/api/org/{id}',
        method: 'get',
    },
    getOrgListSimple: {
        url: '/api/org/simplelist',
        method: 'get',
    },
    addOrg: {
        url: '/api/org',
        method: 'post',
    },
    delOrg: {
        url: '/api/org/{id}',
        method: 'delete',
    },
    editOrg: {
        url: '/api/org/{id}',
        method: 'put',
    },
    getIndexOrg: (http, param) => {
        return http.get(url + `/api/org/${param}`)
    },
    delIndexOrg: (http, param) => {
        return http.delete(url + `/api/org/${param}`)
    },
    //顶图管理 banner
    banner: (http, param) => {
        return http.post(url + '/api/banner', param)
    },
    getBannerList: {
        url: '/api/banner/list',
        method: 'get',
    },
    addSinBanner: {
        url: '/api/banner',
        method: 'post',
    },
    editSinBanner: {
        url: '/api/banner/{id}',
        method: 'put',
    },
    editIndexBanner: {
        url: '/api/banner/list',
        method: 'put',
    },

    getIndexBanner: (http, param) => {
        return http.get(url + `/api/banner/${param}`)
    },
    delIndexBanner: (http, param) => {
        return http.delete(url + `/api/banner/${param}`)
    },
    //获取权限列表
    getLevelsList: (http, param) => {
        return http.get(url + '/api/power/all', param)
    },
    getAllPower: {
        url: 'api/power/list',
        method: 'get',
    },
    //修改
    admin: (http, param) => {
        return http.post(url + '/api/admin', param)
    },
    getAdminList: (http, param) => {
        return http.post(url + '/api/admin/search', param)
    },
    getIndexAdmin: (http, param) => {
        return http.get(url + `/api/admin/${param}`)
    },
    delIndexAdmin: (http, param) => {
        return http.delete(url + `/api/admin/${param}`)
    },
    resetPassword: (http, param) => {
        return http.post(url + '/api/admin/reset', param)
    },
    //用户登录
    login: {
        url: '/login',
        method: 'post',
    },
    //问卷
    questionnaire: (http, param) => {
        return http.post(url + '/api/questionnaire', param)
    },
    getFAQList: {
        url: '/api/questionnaire/list',
        method: 'get',
    },
    getFAQInfo: {
        url: '/api/questionnaire/{id}',
        method: 'get',
    },
    addFAQ: {
        url: '/api/questionnaire',
        method: 'post',
    },
    editFAQ: {
        url: '/api/questionnaire/{id}',
        method: 'put',
    },
    delFAQ: {
        url: '/api/questionnaire/{id}',
        method: 'delete',
    },
    exportFAQ: {
        url: '/api/questionnaire/{id}/export',
        method: 'get',
    },
    getQuestionnaireList: (http, param) => {
        return http.post(url + '/api/questionnaire/search', param)
    },
    getIndexQuestionnaire: (http, param) => {
        return http.get(url + `/api/questionnaire/${param}`)
    },
    delIndexQuestionnaire: (http, param) => {
        return http.delete(url + `/api/questionnaire/${param}`)
    },
    finishIndexQuestion: (http, param) => {
        return http.post(url + `/api/questionnaire/${param}/finish`)
    },
    //志愿者
    // getVolunteerList: (http, param) => {
    //     return http.post(url + `/api/volunteer/search`, param)
    // },
    getVolunteerList: {
        url: '/api/volunteer/list',
        method: 'get'
    },
    getVolunteer: {
        url: '/api/volunteer/{id}',
        method: 'get'
    },
    // getVolunteerIndex: (http, param) => {
    //     return http.get(url + `/api/volunteer/${param}`)
    // },
    // editVolunteer: (http, param) => {
    //     return http.post(url + `/api/volunteer`, param)
    // },
    editVolunteer: {
        url: '/api/volunteer/{id}',
        method: 'put'
    },
    // 管理员
    getManagerList: {
        url: '/api/manager/list',
        method: 'get'
    },
    addManager: {
        url: '/api/manager',
        method: 'post'
    },
    editManager: {
        url: '/api/manager/{id}',
        method: 'put'
    },
    resetManagerPassword: {
        url: '/api/manager/{id}/reset',
        method: 'post'
    },
    delManager: {
        url: '/api/manager/{id}',
        method: 'delete'
    },
    //获取七牛token
    // getqiniutoken: (http, param) => {
    //     return http.get(url + `/api/uptoken`, param)
    // },
    getqiniutoken: {
        url: '/api/uptoken',
        method: 'get'
    },
    //导出志愿者
    exportVolunteer: (http, param) => {
        return http.get(url + `/api/volunteer/export`, param)
    },
    //获取所有消息模板
    // getMsgTpl: (http, param) => {
    //     return http.get(url + `/api/template/list`, param)
    // },
    getMsgTpl: {
        url: '/api/template/list',
        method: 'get'
    },
    //发送消息模板  
    // sendMsgTpl: (http, param) => {
    //     return http.post(url + `/api/template/send`, param)
    // },
    sendMsgTpl: {
        url: '/api/template/send',
        method: 'post'
    },
    //获取自定义菜单列表
    // getMenuList: (http, param) => {
    //     return http.get(url + `/api/menu/list`, param)
    // },
    getMenuList: {
        url: '/api/menu/list',
        method: 'get'
    },
    //保存自定义菜单
    // saveMenu: (http, param) => {
    //     return http.post(url + `/api/menu/edit`, param)
    // },
    saveMenu: {
        url: '/api/menu/edit',
        method: 'put'
    },
    //消息模板查询符合条件人员
    // getMsgCheck: (http, param) => {
    //     return http.post(url + `/api/template/check`, param)
    // },
    getMsgCheck: {
        url: '/api/template/check',
        method: 'post'
    },
    activeFinish: {
        url: '/api/activity/{id}/finish',
        method: 'post'
    },
    exportActive: {
        url: '/api/activity/{id}/export',
        method: 'get',
    },
    getOrgVolunteerAnalysis: {
        url: '/api/org/{id}/volunteeranalysis',
        method: 'get'
    },
    getambassadorlist: {
        url: '/api/org/{id}/ambassadorlist',
        method: 'get'
    }
}