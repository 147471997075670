<template>
  <main-layout>
    <div v-if="!(this.powerListCheck(this.allPower).allPowerCheck)">
      <el-alert
        title="您无权查看本页面。"
        type="warning"
        show-icon
        :closable="false"
      />
    </div>
    <div
      class="pages"
      v-loading="loading"
      v-if="this.powerCheck('获取顶图列表')"
    >
      <el-card class="box-card">
        <div class="topImg_carouselManage topImg_commonManage">
          <div class="topImg_carouselInfoArea topImg_commonInfoArea">
            <h4>首页轮播图</h4>
            <el-upload
              action="https://up-z2.qiniup.com"
              list-type="text"
              :on-success="indexUploadSuccess"
              :before-upload="getQNToken"
              :data="qiniuData"
              accept=".png,.jpg"
              :show-file-list="false"
              :limit="4"
            >
              <el-button
                size="small"
                type="primary"
                :disabled="carousel_fileList.length>=4"
              >新增上传</el-button>
              <template #tip>
                <div class="el-upload__tip">
                  只能上传 jpg/png 文件，建议尺寸750*400，最多上传四张。
                  <br />
                  编辑跳转链接请加上http://或者https://
                  <div v-if="carousel_fileList.length>=4">
                    <br />
                    图片已满4张，如需新增，请删除后重试。
                  </div>
                </div>
              </template>
            </el-upload>
            <br />
            <el-button
              size="small"
              type="primary"
              @click="this.saveIndex"
              :loading="this.saveLoading"
            >保存设置</el-button>
          </div>
          <div class="topImg_carouselListArea topImg_commonListArea">
            <div
              class="topImg_carouselSinList topImg_commonSinList"
              v-for="(item,key) in carousel_fileList"
              :key="key"
            >
              <div
                class="topImg_carouselList_img"
                :style="{backgroundImage:'url('+item.url+')'}"
              />
              <div class="topImg_carouselList_othInfo">
                <div class="topImg_carouselList_othInfo_ImgTitleAndOP">
                  <div class="topImg_carouselList_othInfo_ImgTitle">
                    {{item.name}}
                  </div>
                  <div class="topImg_carouselList_othInfo_ImgOP">
                    <el-button
                      type="text"
                      @click="this.exPositionIndex(key,'up')"
                      :disabled="key == 0"
                    ><i class="el-icon-arrow-up"></i></el-button>
                    <el-button
                      type="text"
                      :disabled="key == (this.carousel_fileList.length-1)"
                      @click="this.exPositionIndex(key,'down')"
                    ><i class="el-icon-arrow-down"></i></el-button>
                    <el-button
                      type="text"
                      style="color:red"
                      @click="this.deleteIndex(key)"
                    ><i class="el-icon-close"></i></el-button>
                  </div>
                </div>

                <el-input
                  placeholder="请输入内容"
                  v-model="item.jump_url"
                  size="small"
                >
                  <template #prepend>跳转链接</template>
                </el-input>
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <div class="topImg_othManage">
        <el-row :gutter="20">
          <el-col
            :span="12"
            v-for="(item,idx) in oth_fileList"
            :key="idx"
          >
            <SingalTopImgToDo
              :originData="item"
              @uploadSuccess="this.chlidUploadSuccess"
            />
          </el-col>
        </el-row>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/Main.vue";
import SingalTopImgToDo from "./singalTopImgToDo";
import { powerCheck, powerListCheck } from "@/tools/util";
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      allPower: ["获取顶图列表"],
      dialogImageUrl: "",
      dialogVisible: false,
      qiniuData: {
        token: "",
      },
      listType: {
        2: ["个人中心", false],
        3: ["公益护照", true],
        4: ["公益证书", true],
        5: ["公益排行", true],
        6: ["我的活动", false],
        7: ["我的称号", true],
        8: ["个人设置", true],
        9: ["我的任务", true],
        10: ["项目", true],
        11: ["志愿者", true],
        12: ["活动排行", true],
        13: ["组织排行", true],
      },
      carousel_fileList: [],
      carousel_ID: [],
      oth_fileList: {},
      dealNow: [],
      saveLoading: false,
    };
  },
  loading: false,
  components: {
    MainLayout,
    SingalTopImgToDo,
  },
  methods: {
    powerCheck,
    powerListCheck,
    chlidUploadSuccess(data) {
      // console.log(data);
      // console.log({ ...this.oth_fileList });
      let newOth_fileList = { ...this.oth_fileList };
      for (let i in newOth_fileList) {
        if (newOth_fileList[i].id == data.id) {
          console.log(newOth_fileList[i].imgURL);
          console.log(data.imgURL);
          newOth_fileList[i].imgURL = data.imgURL;
        }
      }
      console.log(newOth_fileList);
      this.oth_fileList = newOth_fileList;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      console.log(file.url);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    getURLFileName(url) {
      let splitURL = url.split("/");
      return splitURL[splitURL.length - 1];
    },
    getQNToken() {
      return ax("getqiniutoken")
        .then((res3) => {
          console.log(res3);
          //   setQNToken(res3.data);
          this.qiniuData.token = res3.data;
          return true;
        })
        .catch((error) => {
          console.log(error);
          // this.loginNow = true;
          // sessionStorage.clear();
          ElMessage.error("上传失败。");
          return false;
        });
    },
    commonChange(res) {
      console.log(res);
    },
    getData() {
      ax("getBannerList")
        .then((res) => {
          console.log(res);
          // 数据处理
          let carouselList = [];
          let carouselID = [];
          let othList = {};
          for (let i in this.listType)
            if (this.listType[i][1])
              othList[i] = {
                title: this.listType[i][0],
                type: i,
                id: 0,
              };
          for (let i in res.data) {
            if (res.data[i].type == 1) {
              carouselList.push({
                name: this.getURLFileName(res.data[i].imgURL),
                url: res.data[i].imgURL,
                jump_url: res.data[i].jump_url ? res.data[i].jump_url : "",
              });
              carouselID.push(res.data[i].id);
            } else {
              if (othList[res.data[i].type]) {
                console.log(othList[res.data[i].type]);
                console.log(res.data[i]);
                othList[res.data[i].type] = {
                  ...othList[res.data[i].type], //只有title
                  id: res.data[i].id ? res.data[i].id : 0,
                  imgURL: res.data[i].imgURL ? res.data[i].imgURL : "",
                };
              }
            }
          }
          console.log(carouselList);
          console.log(othList);
          console.log(carouselID);
          this.carousel_fileList = carouselList;
          this.carousel_ID = carouselID;
          this.oth_fileList = othList;
        })
        .catch((res) => {
          console.log(res);
          ElMessage.error("获取图片列表失败，请刷新后重试。");
        });
    },
    indexUploadSuccess(res) {
      console.log(res);
      console.log(this.carousel_fileList);
      let newCarousel_fileList = [...this.carousel_fileList];
      if (newCarousel_fileList.length < 4) {
        newCarousel_fileList.push({
          jump_url: "",
          name: res.key,
          url: "https://cdn.fryj.top/" + res.key,
        });
        this.carousel_fileList = newCarousel_fileList;
      }
    },
    deleteIndex(idx) {
      let newCarousel_fileList = [...this.carousel_fileList];
      newCarousel_fileList.splice(idx, 1);
      this.carousel_fileList = newCarousel_fileList;
    },
    exPositionIndex(idx, type) {
      let newCarousel_fileList = [...this.carousel_fileList];
      let thisData = newCarousel_fileList[idx];
      if (type == "up") {
        newCarousel_fileList[idx] = newCarousel_fileList[idx - 1];
        newCarousel_fileList[idx - 1] = thisData;
      } else {
        newCarousel_fileList[idx] = newCarousel_fileList[idx + 1];
        newCarousel_fileList[idx + 1] = thisData;
      }
      console.log(this.carousel_fileList);
      console.log(newCarousel_fileList);
      this.carousel_fileList = newCarousel_fileList;
    },
    saveIndex() {
      let submitJSON = {
        idList: this.carousel_ID,
        bannerList: [],
      };
      this.saveLoading = true;
      for (let i in this.carousel_fileList) {
        submitJSON.bannerList.push({
          imgURL: this.carousel_fileList[i].url,
          describe: "",
          jumpURL: this.carousel_fileList[i].jump_url,
          jumpType: 0,
          type: 1,
        });
      }
      console.log(submitJSON);
      ax("editIndexBanner", { data: submitJSON })
        .then((res) => {
          console.log(res);
          //   setQNToken(res3.data);
          // this.qiniuData.token = res3.data;
          this.saveLoading = false;
          window.location.reload();
          return true;
        })
        .catch((error) => {
          console.log(error);
          // this.loginNow = true;
          // sessionStorage.clear();
          ElMessage.error("保存失败，请重试。");
          this.saveLoading = false;
          return false;
        });
    },
  },

  created() {
    this.getData();
    // this.getQiniuToken();
  },
};
</script>

<style lang="less">
.carouselManage {
  .tips {
    font-size: 13px;
    color: #999;
  }

  .bottom {
    margin-top: 13px;
    line-height: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .button {
    padding: 0;
    min-height: auto;
  }

  .image {
    width: 75px;
    height: 30px;
    display: block;
  }
}
.topImg_commonManage,
.topImg_commonSinList {
  display: flex;
}
.topImg_commonInfoArea {
  flex: 1;
}
.topImg_commonListArea {
  min-width: 675px;
  flex: 2;
}
.topImg_carouselSinList {
  margin-bottom: 8px;
}
.topImg_carouselList_img {
  width: 150px;
  height: 80px;
  background-size: cover;
}
.topImg_carouselList_othInfo {
  padding-left: 8px;
  flex: 1;
}
.topImg_carouselList_othInfo_ImgTitleAndOP {
  display: flex;
  align-items: center;
}
.topImg_carouselList_othInfo_ImgTitle {
  flex: 2;
}
.topImg_carouselList_othInfo_ImgOP {
  flex: 1;
  text-align: right;
}
.topImg_othManage {
  .el-col {
    margin-top: 16px;
  }
}
.topImg_othManageInfo {
  display: flex;
}
.topImg_othManageInfoArea {
  flex: 1;
}
.topImg_othManageImgPreview {
  width: 375px;
  height: 200px;
  background-size: cover;
}
</style>