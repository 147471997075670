<template>
  <div class="pages">
    <div class="content">
      <div class="loginBox">
        <h3>凡人益举</h3>
        <h4>数字化管理服务平台</h4>
        <div class="userIn">
          <div>
            <p>用户名</p>
            <input
              placeholder="请输入用户名"
              type="text"
              v-model="username"
            />
          </div>
          <div>
            <p>密码</p>
            <input
              placeholder="请输入密码"
              type="password"
              v-model="password"
            />
          </div>
          <el-button
            type="info"
            @click="login"
            :loading="this.loginNow"
          >登录</el-button>
        </div>
      </div>
      <div class="imgBox">
        <img
          src="../assets/login.jpg"
          alt
        />
      </div>
    </div>
  </div>
</template>
<script>
// import serviceFactory from "@/service/serviceFactory.js";
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
import {
  clear,
  // isToken,
  setUserName,
  setToken,
  setPowers,
  setUserId,
  setAllPower,
} from "@/tools/util";
export default {
  props: ["flag"],
  data() {
    return {
      username: "",
      password: "",
      loginNow: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // if (isToken()) window.location.href = "/";
      clear();
      // this.$emit("listenToChildEventMenu", false);
    },
    login() {
      if (!this.username) {
        this.$message({
          message: "请输入用户名",
          type: "warning",
        });
        return;
      } else if (!this.password) {
        this.$message({
          message: "请输入密码",
          type: "warning",
        });
        return;
      } else {
        this.loginNow = true;
        ax("login", {
          data: {
            account: this.username,
            password: this.password,
          },
        })
          .then((res) => {
            console.log(res);
            if (res.code != 0) {
              console.log(res.msg);
              this.$message({
                message: res.msg,
                type: "warning",
              });
            } else {
              setUserName(res.data.name);
              setUserId(res.data.id);
              if (res.data.powerList)
                setPowers(JSON.stringify(res.data.powerList));
              setToken(res.data.token);
              ax("getAllPower")
                .then((res2) => {
                  console.log(res2);
                  setAllPower(res2.data);
                  let path = "/";
                  window.location.href = path;
                  //   ax("getqiniutoken")
                  //     .then((res3) => {
                  //       console.log(res3);
                  //       setQNToken(res3.data);
                  //       let path = "/";
                  //       window.location.href = path;
                  //     })
                  //     .catch((error) => {
                  //       console.log(error);
                  //       this.loginNow = true;
                  //       sessionStorage.clear();
                  //       ElMessage.error("登录失败，请检查后重试。");
                  //     });
                })
                .catch((error) => {
                  console.log(error);
                  this.loginNow = false;
                  sessionStorage.clear();
                  ElMessage.error("登录失败，请检查后重试。");
                });
              // console.log("完成登录");
              // vue.$emit("listenToChildEventMenu", true);
              // let path = "/";
              // window.location.href = path;
            }
          })
          .catch((error) => {
            console.log(error);
            // ElMessage.error("登录失败，请检查后重试。");
            this.loginNow = false;
            alert(error.msg);
          });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.userIn {
  width: 335px;
  padding-top: 50px;
  margin: 0 auto;
  text-align: left;
  color: #aaa;
  p {
    margin: 0;
  }
  button {
    display: block;
    width: 165px;
    height: 40px;
    border: 0;
    background: #68a4d8;
    color: #fff;
    font-size: 18px;
    letter-spacing: 2px;
    border-radius: 20px;
    margin: 0 auto;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  div {
    padding-bottom: 40px;
    input {
      display: inline-block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      border: 0;
      font-size: 16px;
      border-bottom: 1px solid #aaa;
      &:focus {
        outline: none;
      }
    }
  }
}
.pages {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 100px;
  background: #d2ebff;
}
.content {
  margin: 0 auto;
  height: 565px;
  width: 1000px;
  display: flex;
  //   box-shadow: 0px 0px 40px 1px #ddd;
}
.loginBox {
  background: #fff;
  flex: 1;
  text-align: center;
  padding-top: 100px;
  color: #68a4d8;
  h3 {
    font-size: 2rem;
    padding-bottom: 20px;
    margin: 0;
  }
  h4 {
    font-size: 1.8rem;
    margin: 0;
  }
}
.imgBox {
  width: 335px;
}
</style>
