<template>
  <el-dialog
    title="新增系统管理员"
    :model-value="this.isVisible"
    width="60%"
    destroy-on-close
    @close="this.$emit('closeAct')"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="assAccount_areaBody">
      <el-form
        ref="submitData"
        :model="submitData"
        label-width="150px"
        :rules="rules"
      >
        <el-form-item
          label="用户名"
          prop="account"
        >
          <el-input v-model="submitData.account"></el-input>
        </el-form-item>
        <el-form-item
          label="密码"
          prop="password"
        >
          <el-input
            v-model="submitData.password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码"
          prop="surekeyword"
        >
          <el-input
            v-model="submitData.surekeyword"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item
          label="姓名"
          prop="name"
        >
          <el-input v-model="submitData.name"></el-input>
        </el-form-item>
        <span style="color:red">*姓名会出现在证书上</span>
        <el-form-item label="用户权限">
          <el-tree
            show-checkbox
            :data="powerList"
            ref="tree"
            node-key="id"
            @check="checkboxclick"
          ></el-tree>
        </el-form-item>
        <el-form-item label="联系人电话">
          <el-input v-model="submitData.tel"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="submitData.remark"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          @click="this.$emit('closeAct')"
          :loading="this.submitLoading"
        >取 消</el-button>
        <el-button
          type="primary"
          @click="this.submit"
          :loading="this.submitLoading"
        >确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { getSinAllPowers } from "@/tools/util";
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
export default {
  props: { isVisible: Boolean, closeAct: Function, addSuccess: Function },
  data() {
    var validatePassword = (rule, value, callback) => {
      console.log(rule);
      if (value !== this.submitData.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      powerList: [],
      submitLoading: false,
      submitData: {
        account: "",
        password: "",
        name: "",
        tel: "",
        remark: "",
        surekeyword: "",
      },
      surekeyword: "",
      rules: {
        account: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        surekeyword: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          { validator: validatePassword, trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
      },
    };
  },
  watch: {
    isVisible(newValue) {
      this.visible = newValue;
    },
  },
  created() {
    // console.log("mounted");
    let groupPower = getSinAllPowers("");
    let allPower = [];
    let powerListUnion = {};
    console.log(groupPower);
    for (let i in groupPower.idx) {
      let thisChlidPower = [];
      for (let j in groupPower.idx[i]) {
        thisChlidPower.push({
          id: groupPower.idx[i][j] + "-" + i,
          label: groupPower[groupPower.idx[i][j]].name,
          pid: groupPower.idx[i][j],
        });
        if (typeof powerListUnion[groupPower.idx[i][j]] == "undefined") {
          powerListUnion[groupPower.idx[i][j]] = [];
        }
        powerListUnion[groupPower.idx[i][j]].push(
          groupPower.idx[i][j] + "-" + i
        );
      }
      allPower.push({ id: `group_${i}`, label: i, children: thisChlidPower });
    }
    // console.log(allPower);
    this.powerList = allPower;
    this.powerListUnion = powerListUnion;
  },
  methods: {
    checkboxclick(nodes) {
      console.log(nodes);
      // 判断是否是父级
      let nowChecked = this.$refs.tree.getCheckedNodes();
      console.log(nowChecked);
      let isChick = false;
      let newPowerID = [];
      for (let i in nowChecked) {
        // console.log(nowChecked[i].$treeNodeId);
        // console.log(nodes.$treeNodeId);
        if (
          typeof nowChecked[i].pid == "number" &&
          newPowerID.indexOf(nowChecked[i].pid) == -1
        ) {
          newPowerID.push(nowChecked[i].pid);
        }
        if (nowChecked[i].$treeNodeId == nodes.$treeNodeId) {
          isChick = true;
        }
      }
      console.log(nowChecked);
      console.log(isChick);
      // 判断是否是父级
      if (nodes.pid) {
        // 子集
        if (!isChick) {
          if (newPowerID.indexOf(nodes.pid) != -1) {
            newPowerID.splice(newPowerID.indexOf(nodes.pid), 1);
          }
        }
      } else {
        //父级
        if (!isChick) {
          // console.log(nodes.children);
          for (let i in nodes.children) {
            // console.log(nodes.children[i].pid);
            if (newPowerID.indexOf(nodes.children[i].pid) != -1) {
              newPowerID.splice(newPowerID.indexOf(nodes.children[i].pid), 1);
            }
            // console.log(newPowerID.join(","));
          }
        }
      }
      console.log(newPowerID);
      this.clickAllPower(newPowerID);
    },
    clickAllPower(powerArray) {
      // console.log(powerArray);
      console.log(this.powerList);
      // console.log(this.powerListUnion);
      let allPower = [];
      for (let i in powerArray) {
        allPower.push(...this.powerListUnion[powerArray[i]]);
      }
      console.log(allPower);
      // 设置所有
      this.$refs.tree.setCheckedKeys(allPower);
    },
    getPowers() {
      // console.log(this.$refs.tree.getCheckedNodes());
      let getPower = this.$refs.tree.getCheckedNodes();
      console.log(getPower);
      let returnData = [];
      for (let i in getPower) {
        if (typeof getPower[i].pid == "number")
          returnData.push(getPower[i].pid);
      }
      return returnData;
    },
    submit() {
      this.$refs["submitData"].validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          let submitJSON = {
            account: this.submitData.account,
            password: this.submitData.password,
            name: this.submitData.name,
            tel: this.submitData.tel,
            remark: this.submitData.remark,
            powerList: this.getPowers(),
          };
          ax("addManager", { data: submitJSON })
            .then((res) => {
              console.log(res);
              this.submitLoading = false;
              // this.$refs["submitData"].resetFields();
              this.$emit("addSuccess", { id: res.data, ...submitJSON });
            })
            .catch((err) => {
              console.log(err);
              this.submitLoading = false;
              ElMessage.error(err.msg);
            });
          //   console.log(submitJSON)
          // 获取权限
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
.assAccount_areaBody {
  max-height: 350px;
  padding: 0 16px;
  overflow: auto;
}
</style>