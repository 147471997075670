<template>
  <el-dialog
    title="新增称号"
    :model-value="this.isVisible"
    width="50%"
    destroy-on-close
    @close="this.$emit('closeAct')"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="assAccount_areaBody">
      <el-form
        ref="submitData"
        :model="submitData"
        label-width="150px"
        :rules="rules"
      >
        <el-form-item
          label="称号名称"
          prop="name"
        >
          <el-input v-model="submitData.name"></el-input>
        </el-form-item>
        <el-form-item
          label="使用方式"
          prop="type"
        >
          <el-radio-group
            v-model="submitData.type"
            size="small"
          >
            <el-radio-button label="1">自动获取</el-radio-button>
            <el-radio-button label="2">后台指定</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="最低分值"
          prop="score"
          v-if="submitData.type == 1"
        >
          <el-input-number
            size="small"
            v-model="submitData.score"
            :min="0"
            :step="0.5"
            :precision="1"
          ></el-input-number>
          <div class="tip">（单位：小时，步长：0.5）</div>
        </el-form-item>
        <el-form-item
          label="称号状态"
          prop="status"
        >
          <el-radio-group
            v-model="submitData.status"
            size="small"
          >
            <el-radio-button label="1">运行</el-radio-button>
            <el-radio-button label="2">锁定</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          @click="this.$emit('closeAct')"
          :loading="this.submitLoading"
        >取 消</el-button>
        <el-button
          type="primary"
          @click="this.submit"
          :loading="this.submitLoading"
        >确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
export default {
  props: { isVisible: Boolean, closeAct: Function, addSuccess: Function },
  data() {
    return {
      visible: false,
      powerList: [],
      submitLoading: false,
      submitData: {
        name: "",
        type: 1,
        score: 0.5,
        status: 1,
      },
      surekeyword: "",
      rules: {
        name: [{ required: true, message: "请输入称号名称", trigger: "blur" }],
      },
    };
  },
  watch: {
    isVisible(newValue) {
      console.log(newValue);
      this.visible = newValue;
      this.submitData = {
        name: "",
        type: 1,
        score: 0.5,
        status: 1,
      };
    },
  },
  methods: {
    getPowers() {
      console.log(this.$refs.tree.getCheckedNodes());
      let getPower = this.$refs.tree.getCheckedNodes();
      let returnData = [];
      for (let i in getPower) {
        if (typeof getPower[i].id == "number") returnData.push(getPower[i].id);
      }
      return returnData;
    },
    submit() {
      this.$refs["submitData"].validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          let submitJSON = { ...this.submitData };
          let toInt = ["type", "status"];
          for (let i in toInt) {
            if (submitJSON[toInt[i]] == "") {
              submitJSON[toInt[i]] = 0;
            } else if (typeof submitJSON[toInt[i]] == "string") {
              submitJSON[toInt[i]] = parseInt(submitJSON[toInt[i]]);
            }
          }
          submitJSON.score = submitJSON.score * 60 * 60;
          ax("addTitle", { data: submitJSON })
            .then((res) => {
              console.log(res);
              this.submitLoading = false;
              // this.$refs["submitData"].resetFields();
              this.$emit("addSuccess", { id: res.data, ...submitJSON });
            })
            .catch((err) => {
              console.log(err);
              this.submitLoading = false;
              ElMessage.error(err.msg);
            });
          //   console.log(submitJSON)
          // 获取权限
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
.assAccount_areaBody {
  max-height: 350px;
  padding: 0 16px;
  overflow: auto;
}
</style>