<template>
  <el-cascader
    :options="options"
    :props="props"
    @change="update"
    v-model="this.thisValue"
    clearable
  ></el-cascader>
</template>

<script>
import { addressDivision, addressDivisionCode } from "./addressDivision";
export default {
  props: {
    updateData: Function,
    propsData: [],
  },
  data() {
    return {
      thisValue: [],
      props: {
        checkStrictly: true,
        lazy: true,
        lazyLoad(node, resolve) {
          const { level, value } = node;
          console.log(level, node);
          let redayData = [];
          if (level == 0) {
            redayData = addressDivision["L1"][0];
          } else {
            redayData = addressDivision[`L${level + 1}`][value];
          }
          console.log(redayData);
          let returnData = [];
          for (let i in redayData) {
            returnData.push({
              value: redayData[i].code,
              label: redayData[i].name,
              leaf: level >= 2,
            });
          }
          console.log(returnData);
          resolve(returnData);
          //   setTimeout(() => {
          //     const nodes = Array.from({ length: level + 1 }).map((item) => ({
          //       value: ++id,
          //       label: `选项${id}-${item}`,
          //       leaf: level >= 2,
          //     }));
          //     // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          //     resolve(nodes);
          //   }, 1000);
        },
      },
    };
  },
  watch: {
    propsData(thisNewValue) {
      console.log(thisNewValue);
      console.log(thisNewValue, this.thisValue);
      let newValue = [];
      if (thisNewValue) {
        newValue = thisNewValue;
      }
      console.log(newValue);
      if (newValue.length == 0) {
        this.value = [];
      } else if (newValue.join(",") != this.thisValue.join(",")) {
        this.value = newValue;
      }
    },
  },
  methods: {
    update(thisValue) {
      console.log(value);
      let value = [];
      if (thisValue) {
        value = thisValue;
      }
      let formatData = {
        value: [],
        label: [],
      };
      if (value[0]) {
        formatData.value.push(value[0]);
        formatData.label.push(addressDivisionCode[value[0]]);
        if (value[1]) {
          formatData.value.push(value[1]);
          formatData.label.push(addressDivisionCode[value[1]]);
          if (value[2]) {
            formatData.value.push(value[2]);
            formatData.label.push(addressDivisionCode[value[2]]);
          }
        }
      }
      console.log(formatData);
      this.$emit("updateData", formatData);
    },
  },
};
</script>
