<template>
  <main-layout>
    <p>About home!123</p>
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/Main.vue'
export default {
  components: {
    MainLayout
  }
}
</script>