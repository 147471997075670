<template>
  <el-drawer
    title="保存问答"
    :model-value="this.isVisible"
    size="80%"
    direction='rtl'
    destroy-on-close
    @close="this.$emit('closeAct')"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="addFAQ_Main">
      <div class="addFAQ_areaBody">
        <el-form
          ref="submitData"
          :model="submitData"
          label-width="150px"
          :rules="rules"
        >
          <el-form-item
            label="活动名称"
            prop="name"
          >
            <el-input v-model="submitData.name"></el-input>
          </el-form-item>
          <el-form-item
            label="是否热门"
            prop="isPopular"
          >
            <el-radio-group
              v-model="submitData.isPopular"
              size="small"
            >
              <el-radio-button label='true'>是</el-radio-button>
              <el-radio-button label='false'>否</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="是否开启提示"
            prop="openPrompt"
          >
            <el-radio-group
              v-model="submitData.openPrompt"
              size="small"
            >
              <el-radio-button label='true'>是</el-radio-button>
              <el-radio-button label='false'>否</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="目标人数"
            prop="target"
          >
            <el-input-number
              v-model="submitData.target"
              :controls="false"
              :min="0"
              :step="1"
              :precision="0"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            label="公益时长"
            prop="volunteerTime"
          >
            <el-input-number
              v-model="submitData.volunteerTime"
              :min="0"
              :step="0.5"
              :precision="1"
            ></el-input-number>
            <div class="tipword">（单位：小时，步长：0.5）</div>
          </el-form-item>
          <el-form-item
            label="参与范围"
            prop="address"
          >
            <el-select-v2
              v-model="submitData.orgIDList"
              :options="this.allOrgs"
              placeholder="请选择"
              style="width: 100%;"
              filterable
              multiple
              clearable
            >
              <template #default="{item}">
                <span style="margin-right: 8px;">{{ item.label }}</span>
                <span style="color: var(--el-text-color-secondary); font-size: 13px;margin-right: 8px;">
                  {{ this.orgType[item.type] }}
                  {{ item.type == "1"?`(${this.schoolType[item.schoolType]})`:"" }}
                </span>
                <span style="color: var(--el-text-color-secondary); font-size: 13px">
                  {{ item.addressWord.join("/") }}
                </span>
              </template>
            </el-select-v2>
            <div class="tipword">（不选择表示所有人都可以参与活动）</div>
            <el-card class="box-card">
              <div>
                快速选择工具:
                <el-button
                  type="primary"
                  size="mini"
                  style="margin-left:16px"
                  @click="this.quickChoose()"
                >执行</el-button>
                <el-button
                  type="info"
                  size="mini"
                  @click="this.reset()"
                >重置</el-button>
              </div>
              <br />
              按地址选择：
              <AddressChoose
                @updateData="this.dealOrgQuickChooseToolAddress"
                :propsData="orgQuickChooseTool.address"
              />
              <br />
              <br />
              按学校类型选择：
              <el-radio-group
                v-model="orgQuickChooseTool.schoolType"
                size="small"
              >
                <el-radio-button label=1>大学</el-radio-button>
                <el-radio-button label=2>高中</el-radio-button>
                <el-radio-button label=3>中职</el-radio-button>
                <el-radio-button label=4>初中</el-radio-button>
                <el-radio-button label=5>小学</el-radio-button>
              </el-radio-group>
            </el-card>
          </el-form-item>
          <el-form-item
            label="活动时间"
            prop="time"
          >
            <el-date-picker
              v-model="submitData.time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </el-form-item>
          <el-form-item label="选择证书">
            <el-select-v2
              v-model="submitData.certificateID"
              :options="this.allCentList"
              placeholder="请选择"
              style="width: 100%;"
              filterable
              clearable
            >
            </el-select-v2>
          </el-form-item>

          <el-form-item
            label="活动封面"
            prop="coverURL"
          >
            <el-upload
              action="https://up-z2.qiniup.com"
              list-type="text"
              :on-success="this.uploadSuccess_coverURL"
              :before-upload="getQNToken"
              :data="qiniuData"
              accept=".png,.jpg"
              :show-file-list="false"
            >
              <el-button
                size="small"
                type="primary"
              >点击上传</el-button>
            </el-upload>
            <div class="tipword">只能上传jpg或png文件，建议尺寸750*400,最多上传一张</div>
            <el-image
              v-if="this.submitData.coverURL != ''"
              style="width: 375px; height: 200px"
              :src="`${this.submitData.coverURL}`"
              :fit="fill"
            ></el-image>
          </el-form-item>
          <el-form-item
            label="活动海报"
            prop="posterURL"
          >
            <el-upload
              action="https://up-z2.qiniup.com"
              list-type="text"
              :on-success="this.uploadSuccess_posterURL"
              :before-upload="getQNToken"
              :data="qiniuData"
              accept=".png,.jpg"
              :show-file-list="false"
            >
              <el-button
                size="small"
                type="primary"
              >点击上传</el-button>
            </el-upload>
            <div class="tipword">只能上传jpg或png文件，建议尺寸比例5:3,最多上传一张</div>
            <el-image
              v-if="this.submitData.posterURL != ''"
              style="width: 300px; height: 500px"
              :src="`${this.submitData.posterURL}`"
              :fit="fill"
            ></el-image>
          </el-form-item>
          <el-form-item
            label="活动顶图"
            prop="backgroundURL"
          >
            <el-upload
              action="https://up-z2.qiniup.com"
              list-type="text"
              :on-success="this.uploadSuccess_backgroundURL"
              :before-upload="getQNToken"
              :data="qiniuData"
              accept=".png,.jpg"
              :show-file-list="false"
            >
              <el-button
                size="small"
                type="primary"
              >点击上传</el-button>
            </el-upload>
            <div class="tipword">只能上传jpg或png文件，建议尺寸比例5:3,最多上传一张</div>
            <el-image
              v-if="this.submitData.backgroundURL != ''"
              style="width: 300px; height: 500px"
              :src="`${this.submitData.backgroundURL}`"
              :fit="fill"
            ></el-image>
          </el-form-item>

          <el-form-item label="活动内容">
            <TinymceEditor
              :value="submitData.describe"
              @updateValue="this.updateContent"
              ref="editor"
            ></TinymceEditor>
          </el-form-item>

        </el-form>
      </div>
      <div class="addFAQ_faqView">
        <el-card
          class="box-card"
          v-for="(item,idx) in this.submitData.questionList"
          :key="idx"
        >
          <h4>问题内容：</h4>
          <div>
            <el-input
              v-model="item.question"
              placeholder="请输入内容"
              size="small"
            ></el-input>
          </div>
          <h4>问题选项：</h4>
          <div>
            <el-row>
              <el-col
                :span="24"
                v-for="(aswItem,aswIdx) in item.answers"
                :key="aswIdx"
              >
                <el-input
                  v-model="item.answers[aswIdx]"
                  placeholder="请输入内容"
                  size="small"
                >
                  <template #prepend>{{aswIdx+1}}</template>
                  <template #append>
                    <el-button
                      icon="el-icon-close"
                      @click="this.delAnswer(idx,aswIdx)"
                    ></el-button>
                  </template>
                </el-input>
              </el-col>
            </el-row>
          </div>
          <h4>正确答案：</h4>
          <div class="qaAswBG">
            <el-checkbox-group
              v-model="item.rightKey"
              size="small"
              :min="1"
            >
              <el-checkbox-button
                v-for="(aswItem,aswIdx) in item.answers"
                :key="aswIdx"
                :label="aswIdx"
              >选项：{{aswIdx+1}}</el-checkbox-button>
            </el-checkbox-group>
          </div>
          <div style="margin-top:8px;">
            <el-button
              size="mini"
              type="primary"
              @click="this.addAnswer(idx)"
            >添加选项</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="this.delQuestion(idx)"
            >删除问题</el-button>
          </div>
        </el-card>
        <div style="margin-top:8px;">
          <el-button @click="this.addQuestion">添加题目</el-button>
          <el-button
            type="primary"
            @click="this.submit"
            :loading="this.submitLoading"
          >提 交</el-button>
        </div>
      </div>
    </div>

  </el-drawer>
</template>
<script>
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
import AddressChoose from "@/components/addressChoose";
import TinymceEditor from "@/components/tinyMCE";
import moment from "moment";
export default {
  props: {
    isVisible: Boolean,
    editFAQID: String,
    closeAct: Function,
    editSuccess: Function,
  },
  data() {
    return {
      visible: false,
      viewName: "张三",
      allOrgs: [],
      allCentList: [],
      editID: 0,
      loading: false,
      submitData: {
        name: "",
        isPopular: false,
        openPrompt: false,
        target: 0, //目标人数
        volunteerTime: 0,
        orgIDList: [],
        time: [],
        startTime: 0,
        endTime: 0,
        certificateID: 0,
        coverURL: "",
        posterURL: "",
        backgroundURL: "",
        describe: "",
        questionList: [],
      },
      orgQuickChooseTool: {
        type: "do",
        address: [],
        schoolType: 0,
      },
      centNameStyle: "top: 0px; left: 0px;",
      qiniuData: {
        token: "",
        // key: "",
      },
      rules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        time: [{ required: true, message: "请选择活动时间", trigger: "blur" }],

        // schoolType: [
        //   { required: true, message: "请输入组织名称", trigger: "blur" },
        //   { validator: validateSchoolType, trigger: "blur" },
        // ],
      },
      schoolType: {
        1: "大学",
        2: "高中",
        3: "中职",
        4: "初中",
        5: "小学",
        0: "未设置",
      },
      orgType: { 1: "学校", 2: "社会", 0: "未设置" },
    };
  },
  watch: {
    isVisible(newValue) {
      // 每次打开时刷新
      this.getAllOrgs();
      this.getAllCents();
      //   console.log(newValue);
      this.visible = newValue;
      if (newValue) {
        this.loading = true;
        setTimeout(() => {
          this.getData();
          console.log(newValue);
        }, 100);
      }

      // this.getData();
    },
    editFAQID(newValue) {
      this.editID = newValue;
    },
  },
  components: {
    AddressChoose,
    TinymceEditor,
  },

  methods: {
    moment,
    updateContent(val) {
      console.log(val);
      this.submitData.describe = val;
    },
    getData() {
      console.log(123);
      if (this.editID == 0) return;
      console.log("获取");
      ax("getFAQInfo", { urlParam: { id: this.editID } })
        .then((res) => {
          console.log(res);
          // if(this.editID ) {}
          let newSubmitJSON = {
            name: "",
            isPopular: false,
            openPrompt: false,
            target: 0, //目标人数
            volunteerTime: 0,
            orgIDList: [],
            time: [],
            startTime: 0,
            endTime: 0,
            certificateID: 0,
            coverURL: "",
            posterURL: "",
            backgroundURL: "",
            describe: "",
            questionList: [],
          };
          for (let i in res.data) newSubmitJSON[i] = res.data[i];
          newSubmitJSON.time = [
            moment(newSubmitJSON.startTime * 1000),
            moment(newSubmitJSON.endTime * 1000),
          ];
          newSubmitJSON.volunteerTime = newSubmitJSON.volunteerTime / 60 / 60;
          this.submitData = newSubmitJSON;
        })
        .catch((err) => {
          console.log(err);
          ElMessage.error("获取活动信息失败，请尝试刷新后重试。");
        });
    },
    addQuestion() {
      let newValue = [
        ...this.submitData.questionList,
        {
          question: "",
          answers: ["", "", "", ""],
          rightKey: [0],
        },
      ];
      this.submitData.questionList = newValue;
    },
    delQuestion(idx) {
      let newValue = [...this.submitData.questionList];
      newValue.splice(idx, 1);
      this.submitData.questionList = newValue;
    },
    addAnswer(idx) {
      let newValue = [...this.submitData.questionList];
      newValue[idx].answers.push("");
      this.submitData.questionList = newValue;
    },
    delAnswer(idx, awsIdx) {
      if (this.submitData.questionList[idx].answers.length <= 1) {
        ElMessage.error("至少保留一个选项。");
        return;
      }
      console.log("111");
      let newValue = [...this.submitData.questionList];
      newValue[idx].answers.splice(awsIdx, 1);
      newValue[idx].rightKey = [0];
      this.submitData.questionList = newValue;
    },
    getAllOrgs() {
      ax("getOrgListSimple")
        .then((res) => {
          console.log(res);
          let thisOrgList = [];
          let reqList = res.data.list;
          for (let i in reqList) {
            let addressCode = [];
            let addressWord = [];
            if (reqList[i].provinceIndex) {
              addressCode.push(reqList[i].provinceIndex);
              addressWord.push(reqList[i].province);
              if (reqList[i].cityIndex) {
                addressCode.push(reqList[i].cityIndex);
                addressWord.push(reqList[i].city);
                if (reqList[i].countyIndex) {
                  addressCode.push(reqList[i].countyIndex);
                  addressWord.push(reqList[i].county);
                }
              }
            }
            thisOrgList.push({
              value: reqList[i].id,
              label: reqList[i].name,
              addressCode,
              addressWord,
              type: reqList[i].type,
              schoolType: reqList[i].schoolType,
            });
          }
          this.allOrgs = thisOrgList;
          //   setQNToken(res3.data);
          //   this.qiniuData.token = res3.data;
          //   return true;
        })
        .catch((error) => {
          console.log(error);
          sessionStorage.clear();
          ElMessage.error(
            "获取组织列表失败，您可能无法选择参与范围，请尝试刷新后重试。"
          );
          //   return false;
        });
    },
    getAllCents() {
      ax("getCertificateListSimple")
        .then((res) => {
          console.log(res);
          let thisCentList = [];
          let reqList = res.data.list;
          for (let i in reqList) {
            thisCentList.push({
              value: reqList[i].id,
              label: reqList[i].name,
            });
          }
          this.allCentList = thisCentList;
          //   setQNToken(res3.data);
          //   this.qiniuData.token = res3.data;
          //   return true;
        })
        .catch((error) => {
          console.log(error);
          sessionStorage.clear();
          ElMessage.error(
            "获取证书失败，您可能无法选择证书，请尝试刷新后重试。"
          );
          //   return false;
        });
    },
    dealOrgQuickChooseToolAddress(addressJson) {
      console.log(addressJson.value);
      this.orgQuickChooseTool.address = addressJson.value;
    },
    quickChoose() {
      // console.log(this.allOrgs[0].addressCode.join(','));
      console.log(this.orgQuickChooseTool.type);
      console.log(this.orgQuickChooseTool.address);
      console.log(this.orgQuickChooseTool.schoolType);
      let addressFilt = this.orgQuickChooseTool.address.length != 0;
      let schoolTypeFilt = this.orgQuickChooseTool.schoolType != 0;
      let dealID = [];
      // console.log(this.allOrgs);
      for (let i in this.allOrgs) {
        console.log(this.allOrgs[i].addressCode);
        let thisAddressCode = [];
        for (let j in this.orgQuickChooseTool.address) {
          thisAddressCode.push(this.allOrgs[i].addressCode[j]);
        }
        console.log(thisAddressCode);
        // if(!this.allOrgs[i].addressCode)  this.allOrgs[i].addressCode=[]
        let t1 = true;
        let t2 = true;
        if (addressFilt)
          t1 =
            this.orgQuickChooseTool.address.join(",") ==
            thisAddressCode.join(",");
        if (schoolTypeFilt)
          t2 = this.allOrgs[i].schoolType == this.orgQuickChooseTool.schoolType;
        // console.log(t1, t2);
        if (t1 && t2) {
          // console.log({ ...this.allOrgs[i] });
          dealID.push(this.allOrgs[i].value);
        }
      }
      console.log(dealID);
      let newOrgIDList = [...this.submitData.orgIDList];
      for (let i in dealID) {
        if (newOrgIDList.indexOf(dealID[i]) == -1) newOrgIDList.push(dealID[i]);
      }
      console.log(newOrgIDList);
      this.submitData.orgIDList = newOrgIDList;
    },
    reset() {
      this.orgQuickChooseTool.address = [];
      this.orgQuickChooseTool.schoolType = 0;
    },
    getQNToken() {
      return ax("getqiniutoken")
        .then((res3) => {
          console.log(res3);
          //   setQNToken(res3.data);
          this.qiniuData.token = res3.data;
          return true;
        })
        .catch((error) => {
          console.log(error);
          // this.loginNow = true;
          // sessionStorage.clear();
          ElMessage.error("上传失败。");
          return false;
        });
    },
    uploadSuccess_posterURL(res) {
      console.log(res);
      this.submitData.posterURL = "https://cdn.fryj.top/" + res.key;
    },
    uploadSuccess_backgroundURL(res) {
      console.log(res);
      this.submitData.backgroundURL = "https://cdn.fryj.top/" + res.key;
    },
    uploadSuccess_coverURL(res) {
      console.log(res);
      this.submitData.coverURL = "https://cdn.fryj.top/" + res.key;
    },
    submit() {
      //   console.log("111");
      this.$refs["submitData"].validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          let submitJSON = { ...this.submitData };
          submitJSON.startTime = moment(submitJSON.time[0]).valueOf() / 1000;
          submitJSON.endTime = moment(submitJSON.time[1]).valueOf() / 1000;
          submitJSON.volunteerTime = submitJSON.volunteerTime * 60 * 60;
          delete submitJSON.time;
          let toNum = [
            "startTime",
            "endTime",
            "certificateID",
            "volunteerTime",
            "target",
          ];
          let toBool = ["isPopular", "openPrompt"];
          for (let i in submitJSON) {
            if (toNum.indexOf(i) != -1 && typeof submitJSON[i] == "string") {
              submitJSON[i] = parseInt(submitJSON[i]);
            }
            if (toBool.indexOf(i) != -1 && typeof submitJSON[i] == "string") {
              submitJSON[i] = submitJSON[i] == "true";
            }
          }
          if (typeof submitJSON.status == "string")
            submitJSON.status = parseInt(submitJSON.status);

          console.log({ ...submitJSON });
          ax("editFAQ", { data: submitJSON, urlParam: { id: this.editID } })
            .then((res) => {
              console.log(res);
              this.submitLoading = false;
              //   this.$refs["submitData"].resetFields();
              this.$emit("editSuccess", {
                ...submitJSON,
                id: this.editID,
              });
            })
            .catch((err) => {
              console.log(err);
              this.submitLoading = false;
              ElMessage.error(err.msg);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
.addFAQ_Main {
  height: 600px;
  padding: 0 16px 16px 16px;
  overflow: auto;
  display: flex;
  height: 100%;
}
.addFAQ_areaBody {
  flex: 3;
  overflow: auto;
  padding: 0 8px;
  height: calc(100vh - 100px);
}
.addFAQ_faqView {
  padding: 0 8px;
  // flex: 1;
  flex: 2;
  height: calc(100vh - 100px);
  overflow: auto;
  border-left: 1px solid #606266;
  h4 {
    margin-top: 8px;
    margin-bottom: 4px;
  }
}
.tips {
  font-size: 12px;
  color: #606266;
  margin-top: 7px;
}
.centName {
  position: relative;
  width: auto;
}
.qaAswBG {
  .el-checkbox-button--small.is-disabled.is-checked {
    background-color: var(--el-color-primary);
    color: var(--el-color-white);
    border-color: var(--el-color-primary);
    .el-checkbox-button__inner {
      color: var(--el-checkbox-button-checked-font-color);
      background-color: var(--el-checkbox-button-checked-background-color);
      border-color: var(--el-checkbox-button-checked-border-color);
      box-shadow: -1px 0 0 0 var(--el-color-primary-light-4);
    }
  }
}
</style>