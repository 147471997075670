<template>
  <el-dialog
    title="新增证书"
    :model-value="this.isVisible"
    width="80%"
    destroy-on-close
    @close="this.$emit('closeAct')"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :lock-scroll="true"
  >
    <div class="addAccount_Main">
      <div class="addAccount_areaBody">
        <el-form
          ref="submitData"
          :model="submitData"
          label-width="150px"
          :rules="rules"
        >
          <el-form-item
            label="证书名称"
            prop="name"
          >
            <el-input v-model="submitData.name"></el-input>
          </el-form-item>
          <el-form-item
            label="证书状态"
            prop="status"
          >
            <el-radio-group
              v-model="submitData.status"
              size="small"
            >
              <el-radio-button label=1>启用</el-radio-button>
              <el-radio-button label=2>禁用</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="上传证书"
            prop="imgURL"
          >
            <el-upload
              action="https://up-z2.qiniup.com"
              list-type="text"
              :on-success="uploadCertIMGSuccess"
              :before-upload="getQNToken"
              :data="qiniuData"
              accept=".png,.jpg"
              :show-file-list="false"
            >
              <el-button
                size="small"
                type="primary"
              >点击上传</el-button>
            </el-upload>
            <div class="tips">
              证书宽高比为 1:√2 ,推荐使用A系列纸张宽高比例；建议图片大小小于500kb；
            </div>
            <h4 style="margin:0">
              姓名定位
            </h4>

            姓名最大宽度：
            <el-input-number
              v-model="submitData.params.width"
              @change="setPosition"
            />
            <div>
              预览名称：<el-input
                v-model="viewName"
                @change="setPosition"
              ></el-input>
              定位信息：
              <el-button
                type="warning"
                size="mini"
                @click="this.resetPosition"
              >重置</el-button>
              X：{{this.submitData.params.left }};
              Y：{{this.submitData.params.top }};
            </div>
            <!-- <el-image
            v-if="this.submitData.logoURL != ''"
            style="width: 100px; height: 100px"
            :src="`${this.submitData.logoURL}`"
            :fit="fill"
          ></el-image> -->
          </el-form-item>

          <el-form-item
            label="上传解锁缩略图"
            prop="thumbnailURL"
          >
            <el-upload
              action="https://up-z2.qiniup.com"
              list-type="text"
              :on-success="uploadlockSuccess"
              :before-upload="getQNToken"
              :data="qiniuData"
              accept=".png,.jpg"
              :show-file-list="false"
            >
              <el-button
                size="small"
                type="primary"
              >点击上传</el-button>
            </el-upload>
            <el-image
              v-if="this.submitData.thumbnailURL != ''"
              style="width: 100px; height: 100px"
              :src="`${this.submitData.thumbnailURL}`"
              :fit="fill"
            ></el-image>
          </el-form-item>

          <el-form-item
            label="上传未解锁缩略图"
            prop="unThumbnailURL"
          >
            <el-upload
              action="https://up-z2.qiniup.com"
              list-type="text"
              :on-success="uploadunlockSuccess"
              :before-upload="getQNToken"
              :data="qiniuData"
              accept=".png,.jpg"
              :show-file-list="false"
            >
              <el-button
                size="small"
                type="primary"
              >点击上传</el-button>
            </el-upload>

            <el-image
              v-if="this.submitData.unThumbnailURL != ''"
              style="width: 100px; height: 100px"
              :src="`${this.submitData.unThumbnailURL}`"
              :fit="fill"
            ></el-image>
          </el-form-item>
        </el-form>
      </div>
      <div class="addAccount_certView">
        <div
          class="certPosition certPosition_add"
          @click="this.getPosition"
          :style="{backgroundImage:`url(${this.submitData.imgURL})`}"
        >
          <div
            class="centName centName_add"
            :style="this.centNameStyle"
          >{{this.viewName}}</div>
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          @click="this.$emit('closeAct')"
          :loading="this.submitLoading"
        >取 消</el-button>
        <el-button
          type="primary"
          @click="this.submit"
          :loading="this.submitLoading"
        >确 定</el-button>
      </span>
    </template>

  </el-dialog>
</template>
<script>
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
export default {
  props: {
    isVisible: Boolean,
    closeAct: Function,
    addSuccess: Function,
  },
  data() {
    return {
      visible: false,
      viewName: "张三",
      submitData: {
        name: "",
        imgURL: "",
        status: 1,
        params: {
          top: 0,
          left: 0,
          width: 120,
          height: 24,
          screenWidth: 420,
          screenHeight: 594,
        },
        thumbnailURL: "",
        unThumbnailURL: "",
      },
      centNameStyle: "top: 0px; left: 0px;",
      qiniuData: {
        token: "",
        // key: "",
      },
      rules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        imgURL: [{ required: true, message: "请选择证书", trigger: "blur" }],
        thumbnailURL: [
          { required: true, message: "请选择解锁缩略图", trigger: "blur" },
        ],
        unThumbnailURL: [
          { required: true, message: "请选择未解锁缩略图", trigger: "blur" },
        ],
        // schoolType: [
        //   { required: true, message: "请输入组织名称", trigger: "blur" },
        //   { validator: validateSchoolType, trigger: "blur" },
        // ],
      },
    };
  },
  watch: {
    isVisible(newValue) {
      console.log(newValue);
      this.visible = newValue;
      this.submitData = {
        name: "",
        imgURL: "",
        status: 1,
        params: {
          top: 0,
          left: 0,
          width: 120,
          height: 24,
          screenWidth: 420,
          screenHeight: 594,
        },
        thumbnailURL: "",
        unThumbnailURL: "",
      };
      this.setPosition();
    },
  },

  methods: {
    getQNToken() {
      return ax("getqiniutoken")
        .then((res3) => {
          console.log(res3);
          //   setQNToken(res3.data);
          this.qiniuData.token = res3.data;
          return true;
        })
        .catch((error) => {
          console.log(error);
          // this.loginNow = true;
          // sessionStorage.clear();
          ElMessage.error("上传失败。");
          return false;
        });
    },
    resetPosition() {
      this.submitData.params = {
        top: 0,
        left: 0,
        width: 120,
        height: 24,
        screenWidth: 420,
        screenHeight: 594,
      };
      // this.centNameStyle = "top: 0px; left: 0px;";
      this.setPosition();
    },
    setPosition() {
      // 获取centName_add 的长边
      let dcu = document.getElementsByClassName("centName_add")[0];
      console.log(dcu);
      console.log(typeof dcu);
      if (typeof dcu != "undefined") {
        // let ofW = dcu.offsetWidth;
        // let ofH = dcu.offsetHeight;
        // let t = 594 * this.submitData.params.py - ofH;
        // let l = 420 * this.submitData.params.px - ofW / 2;
        let t = this.submitData.params.top;
        let l = this.submitData.params.left;
        // 判断最大容纳字符数量
        let wordSize = 12;
        let maxWordNum = parseInt(this.submitData.params.width / 12);
        let viewNameWord = this.viewName;
        if (viewNameWord.length == 0) {
          viewNameWord = " ";
        }
        let maxWordSize = Math.ceil(
          this.submitData.params.width / viewNameWord.length
        );
        console.log(maxWordNum, maxWordSize);
        if (maxWordSize > 14) {
          wordSize = 14;
        }
        if (maxWordSize < viewNameWord.length) {
          this.viewName = viewNameWord.substring(0, maxWordSize);
        }
        this.centNameStyle = `
            top:${t}px;
            left:${l}px;
            width:${this.submitData.params.width}px;
            font-size:${wordSize}px
        `;
        return `
            top:${t}px;
            left:${l}px;
            width:${this.submitData.params.width}px;
            font-size:${wordSize}px;
        `;
      } else {
        this.$nextTick(function () {
          this.setPosition();
        });
        // this.centNameStyle = `
        //     top:0px;
        //     left:0px;
        // `;
        // return `
        //     top:0px;
        //     left:0px;
        // `;
      }
    },
    getPosition(event) {
      let dcu = document.getElementsByClassName("certPosition_add")[0];
      let objTop = this.getOffsetTop(dcu); //对象x位置
      let objLeft = this.getOffsetLeft(dcu); //对象y位置
      console.log(objTop, objLeft);
      //   console.log(e);
      let mouseTop = event.clientY; //鼠标x位置
      let mouseLeft = event.clientX; //鼠标y位置
      console.log(mouseTop, mouseLeft);
      //计算点击的相对位置
      let thisObjLeft = mouseLeft - objLeft;
      let thisObjTop = mouseTop - objTop;
      // let objX = mouseX - objTop;
      // let objY = mouseY - objLeft;
      console.log(thisObjLeft, thisObjTop);

      // let objX = mouseX;
      // let objY = mouseY;

      //   console.log(objX/420);
      //   console.log(objY/594);
      this.submitData.params.top = thisObjTop;
      this.submitData.params.left = thisObjLeft;

      this.setPosition();
      //   return `top:20px;left:30px;`;
    },
    getOffsetTop(obj) {
      console.log(obj);
      let tmp = obj.offsetTop;
      let val = obj.offsetParent;
      while (val != null) {
        tmp += val.offsetTop;
        val = val.offsetParent;
      }
      return tmp;
    },
    getOffsetLeft(obj) {
      let tmp = obj.offsetLeft;
      let val = obj.offsetParent;
      while (val != null) {
        tmp += val.offsetLeft;
        val = val.offsetParent;
      }
      return tmp;
    },
    uploadCertIMGSuccess(res) {
      console.log(res);
      this.submitData.imgURL = "https://cdn.fryj.top/" + res.key;
    },
    uploadlockSuccess(res) {
      console.log(res);
      this.submitData.thumbnailURL = "https://cdn.fryj.top/" + res.key;
    },
    uploadunlockSuccess(res) {
      console.log(res);
      this.submitData.unThumbnailURL = "https://cdn.fryj.top/" + res.key;
    },
    submit() {
      //   console.log("111");
      this.$refs["submitData"].validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          let submitJSON = this.submitData;
          if (typeof submitJSON.status == "string")
            submitJSON.status = parseInt(submitJSON.status);
          console.log({ ...submitJSON });
          ax("addCertificateList", { data: submitJSON })
            .then((res) => {
              console.log(res);
              this.submitLoading = false;
              this.$emit("addSuccess", { id: res.data, ...submitJSON });
              //   this.$refs["submitData"].resetFields();
              // this.$emit("addSuccess", {
              //   ...submitJSON,
              //   // urlParam: { id: this.editID },
              //   id: res.data,
              // });
            })
            .catch((err) => {
              console.log(err);
              this.submitLoading = false;
              ElMessage.error(err.msg);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
.addAccount_Main {
  height: 600px;
  padding: 0 16px;
  overflow: auto;
  display: flex;
}
.addAccount_areaBody {
  flex: 1;
  height: 100%;
  overflow: auto;
}
.addAccount_certView {
  padding: 0 16px;
  // flex: 1;
  width: 460px;
  height: 100%;
}
.certPosition {
  width: 420px;
  min-width: 420px;
  max-width: 420px;
  height: 594px;
  min-height: 594px;
  max-height: 594px;
  background-color: #f6f8f9;
  background-size: 100% 100%;
  cursor: crosshair;
}
.tips {
  font-size: 12px;
  color: #606266;
  margin-top: 7px;
}
.centName {
  position: relative;
  width: auto;
}
.centName_add {
  background-color: rgba(0, 0, 255, 0.1);
  height: 28px;
  line-height: 28px;
  text-align: center;
}
</style>