<template>
  <el-dialog
    title="新增组织"
    :model-value="this.isVisible"
    width="80%"
    destroy-on-close
    @close="this.$emit('closeAct')"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="assAccount_areaBody">
      <el-form
        ref="submitData"
        :model="submitData"
        label-width="150px"
        :rules="rules"
      >
        <el-form-item
          label="组织名称"
          prop="name"
        >
          <el-input v-model="submitData.name"></el-input>
        </el-form-item>
        <el-form-item
          label="组织总人数"
          prop="peopleCount"
        >
          <el-input-number
            v-model="submitData.peopleCount"
            :controls="false"
            :min="0"
            :step="1"
            :precision="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="组织类型"
          prop="surekeyword"
        >
          <div v-if="submitData.parentID != 0">
            {{parentData.name + "-" }}
            {{submitData.type == 1 ? `二级校园组织-${parentData.schoolType}`:"二级社会组织"}}
            <!-- {{submitData.type == 2 && "二级社会组织-"+parentData.name}} -->
          </div>
          <el-radio-group
            v-model="submitData.type"
            size="small"
            v-if="submitData.parentID == 0"
          >
            <el-radio-button label=1>校园组织</el-radio-button>
            <el-radio-button label=2>社会组织</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="组织类型"
          prop="schoolType"
          v-if="this.submitData.type == 1 && submitData.parentID == 0"
        >
          <el-radio-group
            v-model="submitData.schoolType"
            size="small"
          >
            <el-radio-button label=1>大学</el-radio-button>
            <el-radio-button label=2>高中</el-radio-button>
            <el-radio-button label=3>中职</el-radio-button>
            <el-radio-button label=4>初中</el-radio-button>
            <el-radio-button label=5>小学</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="所属地区">
          <AddressChoose
            @updateData="this.dealAddress"
            :propsData="[]"
          />
        </el-form-item>
        <el-form-item
          label="logo"
          prop="logoURL"
        >
          <el-upload
            action="https://up-z2.qiniup.com"
            list-type="text"
            :on-success="uploadSuccess"
            :before-upload="getQNToken"
            :data="qiniuData"
            accept=".png,.jpg"
            :show-file-list="false"
          >
            <el-button
              size="small"
              type="primary"
            >点击上传</el-button>
          </el-upload>
          <el-image
            v-if="this.submitData.logoURL != ''"
            style="width: 100px; height: 100px"
            :src="`${this.submitData.logoURL}`"
            :fit="fill"
          ></el-image>
          <!-- </div> -->

          <!-- <el-dialog v-model="submitData.logoURL">
            <img
              width="100%"
              :src="submitData.logoURL"
              alt=""
            >
          </el-dialog> -->
        </el-form-item>
        <el-form-item label="组织描述">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入组织描述"
            v-model="submitData.describe"
          />
        </el-form-item>

        <!-- <el-form-item label="备注">
          <el-input v-model="submitData.remark"></el-input>
        </el-form-item> -->
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          @click="this.$emit('closeAct')"
          :loading="this.submitLoading"
        >取 消</el-button>
        <el-button
          type="primary"
          @click="this.submit"
          :loading="this.submitLoading"
        >确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { getSinAllPowers } from "@/tools/util";
import AddressChoose from "@/components/addressChoose";
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
export default {
  props: {
    isVisible: Boolean,
    addOrganizationData: Object,
    closeAct: Function,
    addSuccess: Function,
  },
  data() {
    // var validatePassword = (rule, value, callback) => {
    //   console.log(rule);
    //   if (value !== this.submitData.password) {
    //     callback(new Error("两次输入密码不一致!"));
    //   } else {
    //     callback();
    //   }
    // };
    let validateSchoolType = (rule, value, callback) => {
      console.log(rule);
      if (this.submitData.parentID == 0 && this.submitData.type == 1) {
        if (value == "0") {
          callback(new Error("请选择学校类型!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    //validatepeopleCount
    let validatepeopleCount = (rule, value, callback) => {
      console.log(rule);
      if (this.submitData.peopleCount == 0) {
        if (value == "0") {
          callback(new Error("组织人数不能为0!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      visible: false,
      powerList: [],
      submitLoading: false,
      qiniuData: {
        token: "",
        // key: "",
      },
      submitData: {
        name: "",
        describe: "",
        logoURL: "",
        level: 1,
        type: 1,
        peopleCount: 0,
        province: "",
        provinceIndex: "",
        city: "",
        cityIndex: "",
        county: "",
        countyIndex: "",
        schoolType: 0,
        parentID: 0,
        ambassadorID: "", //大使ID（不传）
      },
      parentData: {
        name: "",
        type: "",
        schoolType: "",
      },
      surekeyword: "",
      rules: {
        name: [{ required: true, message: "请输入组织名称", trigger: "blur" }],
        peopleCount: [
          { required: true, message: "请输入总人数", trigger: "blur" },
          { validator: validatepeopleCount, trigger: "blur" },
        ],
        schoolType: [
          { required: true, message: "请输入组织名称", trigger: "blur" },
          { validator: validateSchoolType, trigger: "blur" },
        ],
      },
      schoolType: {
        1: "大学",
        2: "高中",
        3: "中职",
        4: "初中",
        5: "小学",
        0: "未设置",
      },
      orgType: { 1: "学校", 2: "社会", 0: "未设置" },
    };
  },
  components: {
    AddressChoose,
  },
  watch: {
    isVisible(newValue) {
      console.log(newValue);

      this.visible = newValue;
    },
    addOrganizationData(newValue) {
      this.submitData = {
        name: "",
        describe: "",
        logoURL: "",
        level: 1,
        type: 1,
        peopleCount: 0,
        province: "",
        provinceIndex: "",
        city: "",
        cityIndex: "",
        county: "",
        countyIndex: "",
        schoolType: 0,
        parentID: 0,
        ambassadorID: "", //大使ID（不传）
      };
      if (newValue.addParent == -1) {
        // 创建1级组织或学校
        this.submitData.parentID = 0;
        // 初始化等级和类型
        this.submitData.level = 1;
        this.submitData.type = 1;
      } else {
        console.log(newValue);
        // 创建2级组织或学校
        this.submitData.parentID = newValue.addParent;
        this.submitData.level = 2;
        this.submitData.type = newValue.type;
        if (newValue.type == 1) {
          this.submitData.schoolType = newValue.schoolType;
        }
        this.parentData = {
          name: newValue.name,
          type: this.orgType[newValue.type],
          schoolType: this.schoolType[newValue.schoolType],
        };
      }
    },
  },
  created() {
    console.log("mounted");
    let groupPower = getSinAllPowers("");
    let allPower = [];
    for (let i in groupPower.idx) {
      let thisChlidPower = [];
      for (let j in groupPower.idx[i]) {
        thisChlidPower.push({
          id: groupPower.idx[i][j],
          label: groupPower[groupPower.idx[i][j]].name,
        });
      }
      allPower.push({ id: `group_${i}`, label: i, children: thisChlidPower });
    }
    console.log(allPower);
    this.powerList = allPower;
  },
  methods: {
    dealAddress(addressJson) {
      //   console.log(addressJson);
      for (let i in addressJson.value) {
        // console.log(i);
        // console.log(addressJson.value[i]);
        switch (i) {
          case "0":
            // console.log(addressJson.label[i]);
            this.submitData.province = addressJson.label[i];
            this.submitData.provinceIndex = addressJson.value[i];
            break;
          case "1":
            this.submitData.city = addressJson.label[i];
            this.submitData.cityIndex = addressJson.value[i];
            break;
          case "2":
            this.submitData.county = addressJson.label[i];
            this.submitData.countyIndex = addressJson.value[i];
            break;
        }
      }
    },
    getQNToken() {
      return ax("getqiniutoken")
        .then((res3) => {
          console.log(res3);
          //   setQNToken(res3.data);
          this.qiniuData.token = res3.data;
          return true;
        })
        .catch((error) => {
          console.log(error);
          // this.loginNow = true;
          // sessionStorage.clear();
          ElMessage.error("上传失败。");
          return false;
        });
    },
    uploadSuccess(res) {
      console.log(res);
      this.submitData.logoURL = "https://cdn.fryj.top/" + res.key;
    },
    submit() {
      this.$refs["submitData"].validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          // let queryObject = {
          //   pageNum: this.pageNum,
          //   pageSize: this.pageSize,
          //   searchText:this.submitData.name
          // };
          // ax("getOrgList", { data: queryObject })
          //   .then((res) => {
          //     console.log(res);
              
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });


          
          let submitJSON = this.submitData;
          let toInt = [
            "peopleCount",
            "parentID",
            "ambassadorID",
            "schoolType",
            "level",
            "type",
          ];
          for (let i in toInt) {
            if (submitJSON[toInt[i]] == "") {
              submitJSON[toInt[i]] = 0;
            } else if (typeof submitJSON[toInt[i]] == "string") {
              submitJSON[toInt[i]] = parseInt(submitJSON[toInt[i]]);
            }
          }
          if (submitJSON.type == 2) submitJSON.schoolType = 0;
          console.log(submitJSON);
          ax("addOrg", { data: submitJSON })
            .then((res) => {
              console.log(res);
              this.submitLoading = false;
              // this.$refs["submitData"].resetFields();
              this.$emit("addSuccess", { id: res.data, ...submitJSON });
            })
            .catch((err) => {
              console.log(err);
              this.submitLoading = false;
              ElMessage.error(err.msg);
            });


          //   console.log(submitJSON)
          // 获取权限
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
.assAccount_areaBody {
  max-height: 80vh;
  padding: 0 16px;
  overflow: auto;
}
</style>