<template>
  <el-dialog
    title="编辑组织"
    :model-value="this.isVisible"
    width="80%"
    destroy-on-close
    @close="this.$emit('closeAct')"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="assAccount_areaBody">
      <div class="block">
        <span class="demonstration">选择日期：</span>
        <el-date-picker
          v-model="timeArea"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="this.getData()"
        >
        </el-date-picker>
      </div>
      <div
        id="indexChart"
        :style="{ width: '100%', height: '300px' }"
      ></div>
    </div>
  </el-dialog>
</template>
<script>
import * as echarts from "echarts";
import moment from "moment";
// import { ref } from "vue";
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
export default {
  props: {
    isVisible: Boolean,
    statisticsOrganizationData: String,
    closeAct: Function,
  },
  data() {
    return {
      disID: 0,
      visible: false,
      timeArea: [
        moment().startOf("day").subtract(30, "d"),
        moment().startOf("day"),
      ],
    };
  },
  watch: {
    isVisible(newValue) {
      if (!newValue) return;
      console.log(newValue);
      this.visible = newValue;
      this.timeArea = [
        moment().startOf("day").subtract(30, "d"),
        moment().startOf("day"),
      ];
      //   this.timeEnd = moment().startOf("day");
      setTimeout(() => {
        this.getData();
      }, 500);
    },
    statisticsOrganizationData(newValue) {
      console.log(newValue);
      this.disID = newValue;
    },
  },
  methods: {
    getData() {
      let submitJSON = {
        startTime: this.timeArea[0].valueOf() / 1000,
        endTime: this.timeArea[1].valueOf() / 1000,
      };
      console.log(submitJSON);
      ax("getOrgVolunteerAnalysis", {
        urlParam: { id: this.disID },
        data: submitJSON,
      })
        .then((res) => {
          console.log(res);
          let resDataIdx = {};
          let xData = [];
          let volunteerCount = [];
          for (let i in res.data) {
            resDataIdx[res.data[i].date] = res.data[i].volunteerCount;
            xData.push(res.data[i].date);
            // volunteerCount.push(res.data[i].volunteerCount);
          }
          xData.sort();
          console.log(xData);
          for (let i in xData) {
            volunteerCount.push(resDataIdx[xData[i]]);
          }
          let myChart = echarts.init(document.getElementById("indexChart"));
          // 绘制图表
          myChart.setOption({
            title: { text: "用户量" },
            tooltip: {},
            xAxis: {
              data: xData,
            },
            yAxis: {},
            series: [
              {
                name: "用户量",
                type: "line",
                data: volunteerCount,
              },
            ],
          });
          // if(this.editID ) {}
        })
        .catch((err) => {
          console.log(err);
          ElMessage.error("获取统计信息失败。");
        });
    },
  },
};
</script>
<style lang="less">
.assAccount_areaBody {
  max-height: 80vh;
  padding: 0 16px;
  overflow: auto;
}
</style>