<template>
  <div>
    <div class="overallHeader">
      <div>凡人益举数字化管理服务平台</div>
      <div class="more">
        <el-dropdown>
          <el-button
            type="primary"
            size="small"
          >
            更多菜单
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-on:click="this.dialogVisible = true">修改密码</el-dropdown-item>
              <el-dropdown-item v-on:click="this.outLogin">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
          <el-dialog
            title="修改密码"
            v-model="dialogVisible"
            width="30%"
            :before-close="handleClose"
          >
            <el-form
              :model="ruleForm"
              label-width="100px"
            >
              <el-form-item label="当前密码">
                <el-input
                  type="password"
                  v-model="ruleForm.oldPassword"
                ></el-input>
              </el-form-item>
              <el-form-item label="新的密码">
                <el-input
                  type="password"
                  v-model="ruleForm.newPassword"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认新密码">
                <el-input
                  type="password"
                  v-model="ruleForm.TnewPassword"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="editUserPassword"
                >立即修改</el-button>
              </el-form-item>
            </el-form>
          </el-dialog>
          <!-- <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="dialogVisible = true">修改密码</el-dropdown-item>
          <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu> -->
        </el-dropdown>
      </div>
    </div>
    <div class="layoutArea">
      <div class="menuLayout">
        <el-menu
          :uniqueOpened="true"
          :default-active="this.currentRoute"
          @select="go"
          class="el-menu-vertical-demo"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <el-menu-item
            v-for="(item,index) in navList"
            :index="item.url"
            :key="index"
          >
            <i :class="item.icon"></i>
            <span>{{item.name}}</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="pageLayout">
        <div class="pageLayoutBody">
          <slot></slot>
        </div>
        <div class="footer">
          云途科技&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;身处云端，造梦世界。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import routes from "@/routes";
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
import { getUserId } from "@/tools/util.js";
// console.log(routes);
export default {
  data() {
    return {
      navList: [],
      currentRoute: window.location.pathname,
      // routes,
      dialogVisible: false,
      ruleForm: {
        oldPassword: "",
        newPassword: "",
        TnewPassword: "",
      },
    };
  },
  mounted() {
    this.getNavList();
  },
  methods: {
    getNavList() {
      // 权限处理
      let power = [];
      for (let i in routes) {
        // console.log(routes[i]);
        if (routes[i].disabled) continue;
        if (routes[i].power != "" && power.indexOf(routes[i].power) == -1)
          continue;
        this.navList.push({ url: i, ...routes[i] });
      }
      // console.log(this.navList);
    },
    go(index) {
      console.log(index);
      this.$root.currentRoute = index;
      window.history.pushState(null, routes[index], index);
    },
    editUserPassword() {
      if (this.ruleForm.oldPassword == "") {
        this.$message.warning("原始密码不能为空");
        return;
      } else if (this.ruleForm.newPassword == "") {
        this.$message.warning("新密码不能为空");
        return;
      } else if (this.ruleForm.TnewPassword == "") {
        this.$message.warning("第二次输入密码不能为空");
        return;
      } else if (this.ruleForm.oldPassword == this.ruleForm.newPassword) {
        this.$message.warning("新密码不能和原密码相同");
        return;
      } else if (this.ruleForm.newPassword != this.ruleForm.TnewPassword) {
        this.$message.warning("两次密码输入不相同");
        return;
      } else {
        console.log(this.ruleForm.newPassword);

        ax("resetManagerPassword", {
          data: { newPassword: this.ruleForm.newPassword },
          urlParam: { id: parseInt(getUserId()) },
        })
          .then(() => {
            ElMessage.success({
              message: "密码已被重置",
              type: "success",
            });
          })
          .catch((e) => {
            ElMessage.error({
              message: e.msg,
              type: "error",
            });
          });
        // let vue = this
        // ServiceFactory.select("index")
        //   .on("admin", this.$axios, {
        //     id: parseInt(getUserId()),
        //     password: this.ruleForm.newPassword
        //   })
        //   .then(res => {
        //     if (res.data.status == 1) {
        //       vue.$message.success(res.data.msg);
        //     } else {
        //       vue.$message.warning("遇到未知错误");
        //     }
        //   });
        this.dialogVisible = false;
      }
    },
    outLogin() {
      sessionStorage.clear();
      window.location.href = "login";
    },
  },
};
</script> 

<style lang="less" scoped>
.overallHeader {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.layoutArea {
  display: flex;
}
.menuLayout {
  width: 260px;
  background-color: #545c64;
  ul {
    border: 0;
  }
}
.pageLayout {
  flex: 1;
  height: calc(100vh - 51px);
  overflow: auto;
  background-color: #f6f8f9;
}
.pageLayoutBody {
  min-height: calc(100vh - 133px);
  padding: 16px;
}
.footer {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
</style>