<template>
  <div v-loading="loading">
    <el-drawer
      title="查看参与人"
      v-model="visible"
      direction="rtl"
      :before-close="handleClose"
      destroy-on-close
      size="500px"
    >
      <div class="func">
        <el-button
          type="success"
          size="small"
          @click="this.settlement()"
          :loading="this.actLoading"
        >一键结算</el-button>
        <el-button
          type="primary"
          size="small"
          @click="this.settlementChoose()"
          :loading="this.actLoading"
          v-if="this.userList.length > 0"
        >结算选中参与人</el-button>
      </div>
      <!-- <span>我来啦!</span> -->
      <div class="drPA">
        <el-table
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            prop="name"
            label="参与人"
          >
            <template #default="scope">

              <div class="sinUser">
                <div class="sinUserChoose">
                  <el-checkbox
                    v-model="userList"
                    :label="scope.row.id"
                    size="medium"
                    v-if="scope.row.joinActivityStatus != 1"
                  >&nbsp;</el-checkbox>
                </div>

                <div class="sinUserAvatar">
                  <el-avatar
                    :size="50"
                    :src="scope.row.headImg"
                  ></el-avatar>
                </div>

                <div class="sinUserName">
                  <div class="sinName">{{scope.row.name}}</div>
                  <div class="sinNickname">@{{scope.row.nickname}}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label=""
            width="80"
          >
            <template #default="scope">
              {{scope.row.joinActivityStatus == 1?"已结算":""}}
            </template>

          </el-table-column>
          <!-- <el-table-column
            prop="address"
            label="操作"
            width="80"
          >
            <template #default="scope">
              <el-button
                type="success"
                size="small"
                @click="this.creatURL(scope.row.id)"
              >结算</el-button>
            </template>
          </el-table-column> -->
        </el-table>
        <el-pagination
          @size-change="this.sizeChange"
          @current-change="this.pageChange"
          :current-page="this.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="this.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.total"
        >
        </el-pagination>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { ax } from "@/service/http";
import { ElMessage } from "element-plus";
export default {
  props: {
    isVisible: Boolean,
    showID: String,
    closeAct: Function,
  },
  data() {
    return {
      loading: false,
      visible: false,
      actID: 0,
      tableData: [],
      pageNum: 1,
      pageSize: 20,
      total: 1,
      actLoading: false,
      userList: [],
    };
  },
  watch: {
    isVisible(newValue) {
      this.visible = newValue;
      if (newValue) {
        this.tableData = [];
        setTimeout(() => {
          this.getData();
        }, 500);
      }
    },
    showID(newValue) {
      this.actID = newValue;
      // this.getData();
    },
  },
  methods: {
    getData() {
      console.log(this.showID);
      if (this.showID == 0) return;
      this.loading = true;
      let queryObject = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      console.log(queryObject);
      ax("getActivityUserList", {
        data: queryObject,
        urlParam: { id: this.showID },
      })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.list;
          // this.tableData = [
          //   ...res.data.list,
          //   ...res.data.list,
          //   ...res.data.list,
          //   ...res.data.list,
          //   ...res.data.list,
          //   ...res.data.list,
          //   ...res.data.list,
          //   ...res.data.list,
          //   ...res.data.list,
          //   ...res.data.list,
          //   ...res.data.list,
          //   ...res.data.list,
          //   ...res.data.list,
          // ];
          this.total = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClose() {
      this.actID = 0;
      this.$emit("closeAct");
    },
    settlement() {
      console.log(this.actID);
      this.actLoading = true;
      ax("activeFinish", {
        urlParam: { id: this.actID },
      })
        .then((res) => {
          console.log(res);
          this.actLoading = false;
          ElMessage("结算完成");
          // this.$refs["submitData"].resetFields();
          this.$emit("closeAct");
        })
        .catch((err) => {
          console.log(err);
          this.actLoading = false;
          ElMessage.error(err.msg);
        });
    },
    settlementChoose() {
      console.log([...this.userList]);
      this.actLoading = true;
      ax("activeFinish", {
        urlParam: { id: this.actID },
        data: { userIDList: [...this.userList] },
      })
        .then((res) => {
          console.log(res);
          this.actLoading = false;
          ElMessage("结算完成");
          // this.$refs["submitData"].resetFields();
          this.$emit("closeAct");
        })
        .catch((err) => {
          console.log(err);
          this.actLoading = false;
          ElMessage.error(err.msg);
        });
    },
  },
};
</script>
<style lang="less">
.drPA {
  height: 100%;
  overflow: auto;
}
.el-drawer__body {
  overflow: auto;
}
.drPA {
  padding: 0 10px;
}
.sinUser {
  display: flex;
  .sinUserChoose {
    width: 30px;
    height: 60px;
    line-height: 60px;
  }
  .sinUserAvatar {
    width: 60px;
    height: 60px;
  }

  .sinUserName {
    flex: 1;
    .sinName {
      font-size: 16px;
      line-height: 35px;
    }
    .sinNickname {
      font-size: 12px;
      line-height: 15px;
    }
  }
}
.func {
  width: 100%;
  text-align: center;
}
</style>